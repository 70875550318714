import { FC } from "react";
import { Tooltip, Typography, Icon, makeStyles } from "@xcira/components";
import { useAuctionHealthStatusSelector } from "hooks";
import { EventStatus } from "graphql/generated";

const useStyles = makeStyles()((theme) => ({
  healthIndicator: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  healthStatus: {
    display: "flex",
    alignItems: "center",
  },
  statusOk: {
    color: theme.palette.success.main,
  },
  statusWarning: {
    color: theme.palette.warning.main,
  },
  statusError: {
    color: theme.palette.error.main,
  },
  warningList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}));

interface EventHealthStatusProps {
  eventId: number;
  clientEventId: string;
  status: EventStatus;
}

export const EventHealthStatus: FC<EventHealthStatusProps> = ({
  eventId,
  clientEventId,
  status,
}) => {
  const { classes } = useStyles();

  // Only show for Active or Presale events
  const shouldShow = status === EventStatus.Active || status === EventStatus.Presale;

  // Get health status data for this specific event
  const eventHealth = useAuctionHealthStatusSelector((data) =>
    data.auctionHealthStatus.items.find((item) => item.clientEventId === clientEventId)
  );

  // Determine status icon and color
  const getStatusIcon = () => {
    if (!eventHealth) {
      return <Icon icon="help" className={classes.statusWarning} />;
    }

    switch (eventHealth.opsStatus) {
      case "HEALTHY":
        return;
      case "WARNING":
        return <Icon icon="warning" className={classes.statusWarning} />;
      case "ERROR":
        return <Icon icon="error" className={classes.statusError} />;
      default:
        return <Icon icon="help" className={classes.statusWarning} />;
    }
  };

  // Generate tooltip content
  const getTooltipContent = () => {
    if (!eventHealth) {
      return "No health status available";
    }

    if (eventHealth.warnings && eventHealth.warnings.length > 0) {
      return (
        <div>
          <Typography variant="subtitle2">Health Status: {eventHealth.opsStatus}</Typography>
          <Typography variant="body2">Warnings:</Typography>
          <ul className={classes.warningList}>
            {eventHealth.warnings.map((warning, index) => (
              <li key={index}>
                <Typography variant="body2">{warning}</Typography>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return `Health Status: ${eventHealth.opsStatus}`;
  };

  // Don't show anything if the event status isn't Active or Presale
  if (!shouldShow) {
    return null;
  }

  // Get the tooltip title based on health status
  const tooltipTitle = getTooltipContent();

  return (
    <div className={classes.healthIndicator} data-testid={`eventHealthStatus${eventId}`}>
      <Tooltip title={tooltipTitle} arrow>
        <div
          className={classes.healthStatus}
          data-status={typeof tooltipTitle === "string" ? tooltipTitle : "Health Status"}
        >
          {getStatusIcon()}
        </div>
      </Tooltip>
    </div>
  );
};
