import { FC, memo } from "react";
import {
  makeStyles,
  Divider,
  Icon,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@xcira/components";
import { DateUtil } from "@xcira/commons";
import { useAppDispatch, useLoggedInAttendeeCountsByEventId, useSelectEventById } from "hooks";
import { AttendeeTypeEnum, Permission } from "graphql/generated";
import {
  EventHealthStatus,
  InventoryProgress,
  PortsControl,
  RequiresPermissions,
  StreamIconButton,
} from "components";
import { openDialog } from "slices/dialogs";

const useStyles = makeStyles()((theme) => ({
  row: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  titleCase: {
    textTransform: "capitalize",
  },
  launch: {
    width: 40,
  },
  description: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dateTime: {
    minWidth: 160,
  },
  dateDivider: {
    maxWidth: 120,
  },
  inventory: {
    minWidth: 160,
  },
  attendeeCount: {
    minWidth: 24,
    textAlign: "center",
  },
  ports: {
    minWidth: 60,
  },
  portIconStarted: {
    color: theme.palette.success.main,
  },
  portIconStopped: {
    color: theme.palette.error.main,
  },
  av: { minWidth: 80 },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

interface EventRowProps {
  eventId: number;
}

const clerkCountColor = (clerkCount: number) =>
  !clerkCount ? "secondary" : clerkCount > 1 ? "error" : "default";
const clerkIconTitle = (clerkCount: number) =>
  !clerkCount ? "No clerk is active" : clerkCount > 1 ? "More than one clerk is active" : "";

export const EventRow: FC<EventRowProps> = memo(({ eventId }) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const clerkCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Clerk);
  const bidderCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Bidder);
  const viewerCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Viewer);

  const auctionCompany = useSelectEventById(eventId, (event) => event?.auctionCompany);
  const facilityName = useSelectEventById(eventId, (event) => event?.facilityName);
  const description = useSelectEventById(eventId, (event) => event?.description);
  const startTime = useSelectEventById(eventId, (event) => event?.startTime);
  const endTime = useSelectEventById(eventId, (event) => event?.endTime);
  const status = useSelectEventById(eventId, (event) => event?.status);
  const udpatePortNumber = useSelectEventById(eventId, (event) => event?.updatePort);
  const bidPortNumber = useSelectEventById(eventId, (event) => event?.bidPort);
  const masterPortNumber = useSelectEventById(eventId, (event) => event?.masterPort);
  const avPort = useSelectEventById(eventId, (event) => event?.avPort);
  const clientEventId = useSelectEventById(eventId, (event) => event?.clientEventId);
  const lotsClerked = useSelectEventById(eventId, (event) => event?.lotsClerked);

  return (
    <TableRow key={`eventRow${eventId}`} className={classes.row} data-testid={`eventRow${eventId}`}>
      <TableCell data-testid={`eventCellLaunch${eventId}`} className={classes.launch}>
        <IconButton
          onClick={() => dispatch(openDialog({ type: "Attend", props: { eventId } }))}
          data-testid={`eventLaunchButton${eventId}`}
        >
          <Icon icon="launch" color="primary" />
        </IconButton>
      </TableCell>
      <TableCell data-testid={`eventCellAuctionCompany${eventId}`}>{auctionCompany}</TableCell>
      <TableCell data-testid={`eventCellFacilityName${eventId}`}>
        <Link to={`/events/${eventId}`}>{facilityName}</Link>
      </TableCell>
      <TableCell data-testid={`eventCellDescription${eventId}`} className={classes.description}>
        {description}
      </TableCell>
      <TableCell data-testid={`eventCellDateTime${eventId}`} className={classes.dateTime}>
        <>
          <div>{startTime ? DateUtil.format(startTime, "MM/D/YYYY HH:mm") : ""}</div>
          {endTime && (
            <div>
              <Divider className={classes.dateDivider} />
              {DateUtil.format(endTime, "MM/D/YYYY HH:mm")}
            </div>
          )}
        </>
      </TableCell>
      <TableCell data-testid={`eventCellStatus${eventId}`} className={classes.titleCase}>
        <div className={classes.statusContainer}>
          <Typography variant="subtitle2">{status}</Typography>
          {clientEventId && status && (
            <EventHealthStatus eventId={eventId} clientEventId={clientEventId} status={status} />
          )}
        </div>
      </TableCell>
      <TableCell data-testid={`eventCellInventory${eventId}`} className={classes.inventory}>
        <InventoryProgress eventId={eventId} lotsClerked={lotsClerked ?? 0} />
      </TableCell>
      <TableCell
        align="right"
        data-testid={`eventCellClerkCount${eventId}`}
        className={classes.attendeeCount}
      >
        <Typography
          variant="subtitle2"
          color={clerkCountColor(clerkCount)}
          title={clerkIconTitle(clerkCount)}
        >
          {clerkCount}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        data-testid={`eventCellViewerCount${eventId}`}
        className={classes.attendeeCount}
      >
        <Typography variant="subtitle2">{viewerCount}</Typography>
      </TableCell>
      <TableCell
        align="right"
        data-testid={`eventCellBidderCount${eventId}`}
        className={classes.attendeeCount}
      >
        <Typography variant="subtitle2">{bidderCount}</Typography>
      </TableCell>
      <RequiresPermissions permissions={Permission.PortsRead}>
        <TableCell data-testid={`eventCellPortStatus${eventId}`} className={classes.ports}>
          <PortsControl
            eventId={eventId}
            updatePortNumber={udpatePortNumber}
            bidPortNumber={bidPortNumber}
            masterPortNumber={masterPortNumber}
          />
        </TableCell>
      </RequiresPermissions>

      <RequiresPermissions permissions={Permission.PortsRead}>
        <TableCell align="center" className={classes.av}>
          <StreamIconButton
            avPort={avPort}
            facilityName={facilityName}
            auctionCompany={auctionCompany}
            clientEventId={clientEventId}
          />
        </TableCell>
      </RequiresPermissions>
    </TableRow>
  );
});
