import { ChangeEvent, FC, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Ports, Attendees, RequiresPermissions, ToolsDialog } from "components";
import type { RouterParams } from "components/ProtectedRoutes";
import { Dayjs } from "dayjs";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  Link,
  CircularProgress,
  IconButton,
  Icon,
  DateTimePicker,
} from "@xcira/components";
import {
  useCompanies,
  useEvents,
  useSelectEventById,
  useLoggedInAttendeeCountsByEventId,
  useAvPublishers,
  useAvStreams,
  usePortStatus,
  useAttendees,
  useLoggedInAttendeeCounts,
  useSaleTitleAsWindowTitle,
  useHighestPlayerVersion,
  useUpdateEventDates,
} from "hooks";
import { AttendeeTypeEnum, Permission } from "graphql/generated";
import { InventoryProgress, StreamManagement } from "components";
import { POLLING_INTERVAL } from "constants/constants";
import { DateUtil } from "@xcira/commons";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  pageHeader: {
    marginBottom: theme.spacing(4),
  },
  portsBox: {
    borderWidth: "1px",
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
    minWidth: 220,
    height: "100%",
  },
  clerkedProgress: {
    display: "flex",
    width: "100%",
    position: "relative",
  },
  clerkedProgressBar: {
    height: theme.spacing(2.5),
    borderRadius: theme.spacing(0.5),
    width: "100%",
    minWidth: 160,
  },
  progressLabel: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
  },
  tabsBox: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  tab: {
    borderWidth: "1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[100],
    borderStyle: "solid",
  },
  tabSelected: {
    backgroundColor: theme.palette.grey[200],
    boxShadow: "inset 0px 0px 3px rgba(0, 0, 0, 0.2)",
  },
  box: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 220,
    borderWidth: "1px",
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
    height: "100%",
  },
  invetoryBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 220,
    borderWidth: "1px",
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
  },
  portStatusUpdate: {
    display: "flex",
    padding: theme.spacing(1),
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  status: {
    textTransform: "uppercase",
    paddingRight: theme.spacing(2),
  },
  actionGrid: {
    marginBottom: theme.spacing(5),
  },
  gridContainer: {
    marginBottom: theme.spacing(1),
  },
  refetch: {
    textAlign: "right",
  },
}));

const requiredPermissions = [Permission.AttendeesRead, Permission.AvStreamsRead];

const clerkCountColor = (clerkCount: number) =>
  !clerkCount ? "secondary" : clerkCount > 1 ? "error" : "primary";
const clerkIconTitle = (clerkCount: number) =>
  !clerkCount ? "Clerk is not logged in" : clerkCount > 1 ? "Multiple clerks are logged in" : "";

const EventDetails: FC = () => {
  const [tabValue, setTabValue] = useState(0);

  const { refetch: refetchAvPublishers } = useAvPublishers(POLLING_INTERVAL);
  useAvStreams();
  useHighestPlayerVersion();
  usePortStatus();

  const { isLoading: eventsLoading, refetch: refetchEvents } = useEvents();
  const { isLoading: companiesLoading } = useCompanies();
  const isLoading = eventsLoading || companiesLoading;

  const { eventId: id } = useParams<RouterParams>();
  const eventId = Number(id);
  const { classes } = useStyles();

  const event = useSelectEventById(eventId);

  useSaleTitleAsWindowTitle(event?.facilityName);

  const cacheKey = useMemo(() => [eventId], [eventId]);

  const { refetch: refetchAttendees } = useAttendees(eventId, POLLING_INTERVAL);
  const { refetch: refetchAttendeeCounts } = useLoggedInAttendeeCounts(
    { eventIds: [eventId] },
    POLLING_INTERVAL
  );

  const clerkCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Clerk, cacheKey);
  const bidderCount = useLoggedInAttendeeCountsByEventId(
    eventId,
    AttendeeTypeEnum.Bidder,
    cacheKey
  );
  const viewerCount = useLoggedInAttendeeCountsByEventId(
    eventId,
    AttendeeTypeEnum.Viewer,
    cacheKey
  );
  const techcoordCount = useLoggedInAttendeeCountsByEventId(
    eventId,
    AttendeeTypeEnum.Techcoord,
    cacheKey
  );

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  const [updateEventDates] = useUpdateEventDates();

  const handleStartDateChange = async (value: Dayjs | null) => {
    if (value && event) {
      const endTime = event.endTime ? new Date(event.endTime) : value.toDate();
      await updateEventDates(event.id, value.toDate(), endTime);
      refetch();
    }
  };

  const handleEndDateChange = async (value: Dayjs | null) => {
    if (value && event) {
      const startTime = event.startTime ? new Date(event.startTime) : new Date();
      await updateEventDates(event.id, startTime, value.toDate());
      refetch();
    }
  };

  const refetch = () => {
    refetchEvents();
    refetchAttendees();
    refetchAvPublishers();
    refetchAttendeeCounts();
  };

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="eventDetailsLoader"
        className={classes.circularProgress}
        size={100}
      />
    );
  }

  if (!event) {
    return (
      <Typography variant="h5" color="textPrimary" data-testid="eventDetailsNotLoaded">
        Event details could not be loaded. Please try again.
      </Typography>
    );
  }

  const { facilityName, status, auctionCompany } = event;

  return (
    <div className={classes.root} data-testid="eventDetails">
      <Grid container justifyContent="center" className={classes.pageHeader}>
        <Grid item>
          <Typography variant="h6">Event details of {facilityName}</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Link to="/events" data-testid="backToEvents">
            Back to events
          </Link>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="subtitle2">Event Status: </Typography>
            </Grid>
            <Grid item className={classes.status}>
              <Typography variant="subtitle2" data-testid="eventDetailsStatus">
                {status}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.actionGrid}>
        <Grid item flex={1} className={classes.refetch}>
          <IconButton onClick={refetch}>
            <Icon icon="replay" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={2} data-testid="eventDetailsInfoBlock">
        <Grid item xs={12} sm={6} md={4} data-testid="eventDetailsInfo">
          <Box className={classes.box}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Auction House</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" data-testid="eventDetailsAuctionCompany">
                  {auctionCompany}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">SaleNo</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" data-testid="eventDetailsSaleNo">
                  {event.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Client Event ID</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" data-testid="eventDetailsClientEventId">
                  {event.clientEventId}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Facility ID</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" data-testid="eventDetailsFacilityId">
                  {event.facilityId}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Facility Name</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" data-testid="eventDetailsFacilityName">
                  {event.facilityName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Start Date Time</Typography>
              </Grid>
              <Grid item>
                <DateTimePicker
                  value={event.startTime ? DateUtil.toDayjs(new Date(event.startTime)) : null}
                  onAccept={handleStartDateChange}
                  // renderInput={(props: TextFieldProps) => <TextField {...props} />}
                  data-testid="eventDetailsStartDate"
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">End Date Time</Typography>
              </Grid>
              <Grid item>
                <DateTimePicker
                  value={event.endTime ? DateUtil.toDayjs(new Date(event.endTime)) : null}
                  onAccept={handleEndDateChange}
                  // renderInput={(props) => <TextField {...props} />}
                  data-testid="eventDetailsEndDate"
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Description</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" data-testid="eventDetailsDescription">
                  {event.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} data-testid="eventDetailsCounts">
          <Box className={classes.box}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Clerk</Typography>
              </Grid>
              <Grid item>
                <Typography
                  color={clerkCountColor(clerkCount)}
                  title={clerkIconTitle(clerkCount)}
                  data-testid="eventDetailsClerkCount"
                >
                  {clerkCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Techcoord</Typography>
              </Grid>
              <Grid item>
                <Typography data-testid="eventDetailsTechcoordCount">{techcoordCount}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Viewers</Typography>
              </Grid>
              <Grid item>
                <Typography data-testid="eventDetailsViewersCount">{viewerCount}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Bidders</Typography>
              </Grid>
              <Grid item>
                <Typography data-testid="eventDetailsBiddersCount">{bidderCount}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="subtitle2">Viewers and Bidders</Typography>
              </Grid>
              <Grid item>
                <Typography data-testid="eventDetailsViewersBiddersCount">
                  {viewerCount + bidderCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} data-testid="eventDetailsPorts">
          <Box className={classes.portsBox}>
            <Ports
              eventId={eventId}
              updatePortNumber={event?.updatePort}
              bidPortNumber={event?.bidPort}
              masterPortNumber={event?.masterPort}
              allowUpdates
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2} data-testid="eventDetailsInventoryProgress">
          <Box className={classes.invetoryBox}>
            <Typography variant="subtitle2">Inventory</Typography>
            <div className={classes.flexRow}>
              <InventoryProgress
                eventId={eventId}
                lotsClerked={event.lotsClerked ?? 0}
              ></InventoryProgress>
            </div>
          </Box>
        </Grid>
      </Grid>

      <RequiresPermissions permissions={requiredPermissions}>
        <Box className={classes.tabsBox}>
          <Tabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab
              value={0}
              label="Attendees"
              color="default"
              classes={{ root: classes.tab, selected: classes.tabSelected }}
              data-testid="eventDetailsAttendeesTab"
            />

            <Tab
              value={1}
              label="Stream Management"
              color="default"
              classes={{ root: classes.tab, selected: classes.tabSelected }}
              data-testid="eventDetailsStreamManagementTab"
            />
          </Tabs>
          {tabValue === 0 && (
            <RequiresPermissions permissions={Permission.AvStreamsRead}>
              <Attendees eventId={eventId} ahco={auctionCompany} />
            </RequiresPermissions>
          )}

          {tabValue === 1 && (
            <RequiresPermissions permissions={Permission.AvStreamsRead}>
              <StreamManagement eventId={eventId} />
            </RequiresPermissions>
          )}
        </Box>
      </RequiresPermissions>

      <ToolsDialog />
    </div>
  );
};

export { EventDetails };
