import { FC } from "react";
import {
  DatePicker as MuiDatePicker,
  DateTimePicker as MuiDateTimePicker,
  DatePickerProps,
  DateTimePickerProps,
} from "@mui/x-date-pickers";
import { Dayjs } from "@xcira/commons";

export const DatePicker: FC<DatePickerProps<Dayjs>> = ({ ...props }) => {
  return <MuiDatePicker {...props} />;
};
export const DateTimePicker: FC<DateTimePickerProps<Dayjs>> = ({ ...props }) => {
  return <MuiDateTimePicker {...props} />;
};

export type { DatePickerProps, DateTimePickerProps };
