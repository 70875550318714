import { GetAuctionHealthStatusQuery } from "graphql/generated";
import { cloudServicesEndpoints } from "services";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultResult: GetAuctionHealthStatusQuery = { auctionHealthStatus: { items: [] } };

function useAuctionHealthStatusSelector<
  T extends (data: GetAuctionHealthStatusQuery) => ReturnType<T>
>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetAuctionHealthStatus.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data ?? defaultResult),
  });
}

export { useAuctionHealthStatusSelector };
