import { useUpdateEventDatesMutation } from "services";
import { useCallback } from "react";

function useUpdateEventDates() {
  const [updateEventDates, result] = useUpdateEventDatesMutation();

  const callback = useCallback(
    async (eventId: number, startDateTime: Date, endDateTime: Date) => {
      const result = await updateEventDates({ eventId, startDateTime, endDateTime }).unwrap();

      // TODO: invalidate events tag?

      return result;
    },
    [updateEventDates]
  );

  return [callback, result] as const;
}

export { useUpdateEventDates };
