import { api } from "./EnhancedCloudServices";
import { RootState } from "app/store";

export const {
  useLoginMutation,
  useAttendMutation,
  useMultiAttendMutation,
  useGetUserQuery,
  useGetAuctionEventsQuery,
  useGetCompaniesQuery,
  useGetAttendeesQuery,
  useGetAvPublishersQuery,
  useGetAvStreamsQuery,
  useGetPreviewFlavorsQuery,
  useGetLoggedInAttendeeCountsQuery,
  useGetCumulativeStatsQuery,
  useSetAvPublisherActionMutation,
  useSetAvPublisherGainMutation,
  useUpdateEventStatusMutation,
  useUpdateEventDatesMutation,
  useUpdatePortStatusByPortNumberMutation,
  useChangeAvConnectionMutation,
  useGetAvStreamNameByAudioPortQuery,
  useGetHighestPlayerVersionQuery,
  reducer: cloudServicesReducer,
  reducerPath: cloudServicesReducerPath,
  endpoints: cloudServicesEndpoints,
  middleware: cloudServicesMiddleware,
} = api;

export const cloudServices = api;

export const selectCompanies = (state: RootState) =>
  api.endpoints.GetCompanies.select(undefined)(state);
export const selectEvents = (state: RootState) =>
  api.endpoints.GetAuctionEvents.select(undefined)(state);
export const selectFacilities = (state: RootState) =>
  api.endpoints.GetFacilities.select(undefined)(state);
