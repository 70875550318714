import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from "graphql";
import { api } from "services/cloud/CloudServices";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AppInfo = {
  __typename?: "AppInfo";
  envName: Scalars["String"];
};

export type AttendeeCount = {
  __typename?: "AttendeeCount";
  count: Scalars["Float"];
  eventId?: Maybe<Scalars["Float"]>;
  type: AttendeeTypeEnum;
};

export enum AttendeeTypeEnum {
  Bidder = "BIDDER",
  Clerk = "CLERK",
  Guest = "GUEST",
  GuestBidder = "GUEST_BIDDER",
  GuestMarquee = "GUEST_MARQUEE",
  GuestTechcoord = "GUEST_TECHCOORD",
  GuestViewer = "GUEST_VIEWER",
  Marquee = "MARQUEE",
  Opsviewer = "OPSVIEWER",
  Ringrep = "RINGREP",
  Techcoord = "TECHCOORD",
  Viewer = "VIEWER",
}

export type AuctionAttendee = {
  __typename?: "AuctionAttendee";
  clientAttendeeId: Scalars["String"];
  creditLimit?: Maybe<Scalars["Float"]>;
  eventId: Scalars["Float"];
  id: Scalars["Float"];
  name: Scalars["String"];
  sellerId?: Maybe<Scalars["String"]>;
  type: AttendeeTypeEnum;
  warranty?: Maybe<Scalars["String"]>;
};

export type AuctionCompany = {
  __typename?: "AuctionCompany";
  auctionCompany: Scalars["String"];
  customerLogo?: Maybe<Scalars["String"]>;
  flavorGroup?: Maybe<FlavorGroup>;
  flavorGroupName?: Maybe<Scalars["String"]>;
  fullName: Scalars["String"];
  id: Scalars["Float"];
  isActive: Scalars["Boolean"];
  showCustomerSalesLinks?: Maybe<Scalars["Boolean"]>;
};

export type AuctionEvent = {
  __typename?: "AuctionEvent";
  auctionCompany: Scalars["String"];
  avPort?: Maybe<Scalars["Float"]>;
  bidPort: Scalars["Float"];
  clientAuctionId?: Maybe<Scalars["String"]>;
  clientEventId: Scalars["String"];
  description: Scalars["String"];
  endTime: Scalars["Float"];
  facilityId: Scalars["Float"];
  facilityName: Scalars["String"];
  id: Scalars["Float"];
  inventoryCounts?: Maybe<Array<InventoryCount>>;
  inventoryTable: Scalars["String"];
  isPaused: Scalars["Boolean"];
  lotsClerked?: Maybe<Scalars["Float"]>;
  masterPort: Scalars["Float"];
  startTime: Scalars["Float"];
  status: EventStatus;
  updatePort: Scalars["Float"];
};

export type AuctionHealthStatusItem = {
  __typename?: "AuctionHealthStatusItem";
  ahco: Scalars["String"];
  clientEventId: Scalars["String"];
  eventStatus: Scalars["String"];
  facilityName: Scalars["String"];
  opsStatus: Scalars["String"];
  warnings?: Maybe<Array<Scalars["String"]>>;
};

export type AuctionHealthStatusResponse = {
  __typename?: "AuctionHealthStatusResponse";
  items: Array<AuctionHealthStatusItem>;
};

export type AvConnection = {
  __typename?: "AvConnection";
  description: Scalars["String"];
  edgeUrl?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
  name: Scalars["String"];
};

export enum AvConnectionTypeEnum {
  Alternate = "ALTERNATE",
  Backup = "BACKUP",
  Primary = "PRIMARY",
}

export type AvEncoderSettings = {
  __typename?: "AvEncoderSettings";
  audioBitrate: Scalars["Float"];
  avStreams?: Maybe<Array<AvStream>>;
  description: Scalars["String"];
  encoderName?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
  videoFps: Scalars["Float"];
};

export type AvPublisher = {
  __typename?: "AvPublisher";
  action?: Maybe<Scalars["String"]>;
  avStream: AvStream;
  description: Scalars["String"];
  gain?: Maybe<Scalars["Float"]>;
  id: Scalars["Float"];
  info?: Maybe<Scalars["String"]>;
  infoRaw?: Maybe<AvPublisherInfo>;
  lastModified: Scalars["Float"];
  machineIp?: Maybe<Scalars["String"]>;
  restartTime?: Maybe<Scalars["Float"]>;
  startTime: Scalars["Float"];
  statisticsRaw?: Maybe<AvPublisherStatistics>;
  status: AvStreamStatus;
  statusInfo?: Maybe<Scalars["String"]>;
  statusRaw?: Maybe<AvPublisherStatus>;
  streamId: Scalars["Float"];
};

export type AvPublisherCamera = {
  __typename?: "AvPublisherCamera";
  bandwidth?: Maybe<Scalars["Float"]>;
  codec?: Maybe<Scalars["String"]>;
  fps?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Float"]>;
  keyframeinterval?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["String"]>;
  muted?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  profile?: Maybe<Scalars["String"]>;
  quality?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type AvPublisherEnvironment = {
  __typename?: "AvPublisherEnvironment";
  debug?: Maybe<Scalars["Boolean"]>;
  os?: Maybe<Scalars["String"]>;
  playerversion?: Maybe<Scalars["String"]>;
};

export type AvPublisherInfo = {
  __typename?: "AvPublisherInfo";
  camera?: Maybe<AvPublisherCamera>;
  env?: Maybe<AvPublisherEnvironment>;
  mic?: Maybe<AvPublisherMic>;
};

export type AvPublisherMic = {
  __typename?: "AvPublisherMic";
  codec?: Maybe<Scalars["String"]>;
  framesperpacket?: Maybe<Scalars["Float"]>;
  muted?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  quality?: Maybe<Scalars["Float"]>;
  rate?: Maybe<Scalars["Float"]>;
};

export type AvPublisherStatistics = {
  __typename?: "AvPublisherStatistics";
  audioBitrate?: Maybe<Scalars["Float"]>;
  bitrate?: Maybe<Scalars["Float"]>;
  protocol?: Maybe<Scalars["String"]>;
  videoBitrate?: Maybe<Scalars["Float"]>;
  videoFramerate?: Maybe<Scalars["Float"]>;
  videoHeight?: Maybe<Scalars["Float"]>;
  videoWidth?: Maybe<Scalars["Float"]>;
};

export type AvPublisherStatus = {
  __typename?: "AvPublisherStatus";
  activity?: Maybe<AvPublisherStatusActivity>;
  status?: Maybe<AvStreamStatus>;
  statusinfo?: Maybe<Scalars["String"]>;
};

export type AvPublisherStatusActivity = {
  __typename?: "AvPublisherStatusActivity";
  camera?: Maybe<Scalars["Float"]>;
  mic?: Maybe<Scalars["Float"]>;
};

export type AvStream = {
  __typename?: "AvStream";
  audioPort?: Maybe<Scalars["Float"]>;
  avConnection: AvConnection;
  avConnectionAlternate?: Maybe<AvConnection>;
  avConnectionBackup?: Maybe<AvConnection>;
  avConnectionPrimary?: Maybe<AvConnection>;
  avEncoderSettings: AvEncoderSettings;
  avPublishers?: Maybe<Array<AvPublisher>>;
  connectionId: Scalars["Float"];
  encoderId: Scalars["Float"];
  id: Scalars["Float"];
  name: Scalars["String"];
  streamType: StreamTypeEnum;
};

export enum AvStreamStatus {
  Open = "OPEN",
  Paused = "PAUSED",
  Reconnecting = "RECONNECTING",
  Stopped = "STOPPED",
  Streaming = "STREAMING",
}

export type CumulativeStatDto = {
  __typename?: "CumulativeStatDto";
  acceptedBids?: Maybe<Scalars["Float"]>;
  acceptedNetBids?: Maybe<Scalars["Float"]>;
  auctionName: Scalars["String"];
  averageItemOlr?: Maybe<Scalars["Float"]>;
  averagePerItem?: Maybe<Scalars["Float"]>;
  biddersAttended?: Maybe<Scalars["Float"]>;
  date: Scalars["String"];
  difItemsNetAccepted?: Maybe<Scalars["Float"]>;
  endTime: Scalars["String"];
  id: Scalars["Float"];
  lotsClerked?: Maybe<Scalars["Float"]>;
  netPurchasedItems?: Maybe<Scalars["Float"]>;
  netWithoutBid?: Maybe<Scalars["Float"]>;
  percentageOlrBid?: Maybe<Scalars["Float"]>;
  percentageOlrRunnerup?: Maybe<Scalars["Float"]>;
  percentageOlrSold?: Maybe<Scalars["Float"]>;
  percentageSalesOlr?: Maybe<Scalars["Float"]>;
  ringmanName: Scalars["String"];
  runningTime?: Maybe<Scalars["Float"]>;
  secondHighest?: Maybe<Scalars["Float"]>;
  soldToNet?: Maybe<Scalars["Float"]>;
  startTime: Scalars["String"];
  totalCars?: Maybe<Scalars["Float"]>;
  totalLoginDuration?: Maybe<Scalars["Float"]>;
  totalNetSales?: Maybe<Scalars["Float"]>;
  totalSales?: Maybe<Scalars["Float"]>;
  totalSold?: Maybe<Scalars["Float"]>;
};

export type CumulativeStatsDto = {
  __typename?: "CumulativeStatsDto";
  cumulativeStats?: Maybe<Array<CumulativeStatDto>>;
  totals: CumulativeStatsTotalsDto;
};

export type CumulativeStatsTotalsDto = {
  __typename?: "CumulativeStatsTotalsDto";
  totalAcceptedBidsOlr: Scalars["Float"];
  totalAttendeesOlr: Scalars["Float"];
  totalAttendeesOlrBid: Scalars["Float"];
  totalAttendeesOlrWithoutBid: Scalars["Float"];
  totalAttendeesOlrWon: Scalars["Float"];
  totalAveragePerItem: Scalars["Float"];
  totalAveragePerItemOlr: Scalars["Float"];
  totalItems: Scalars["Float"];
  totalLoginTime: Scalars["Float"];
  totalLotsClerked: Scalars["Float"];
  totalPercentageOlrBid: Scalars["Float"];
  totalPercentageOlrRunnerup: Scalars["Float"];
  totalPercentageOlrSold: Scalars["Float"];
  totalPercentageSalesOlr: Scalars["Float"];
  totalRunningTime: Scalars["Float"];
  totalSales: Scalars["Float"];
  totalSalesOlr: Scalars["Float"];
  totalSold: Scalars["Float"];
  totalSoldOlr: Scalars["Float"];
  totalSoldOlrBid: Scalars["Float"];
  totalSoldRunnerup: Scalars["Float"];
};

export type CustomerSalesLink = {
  __typename?: "CustomerSalesLink";
  ahco: Scalars["String"];
  clientEventId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["Float"]>;
  eventId?: Maybe<Scalars["Float"]>;
  facilityName: Scalars["String"];
  laneFullName?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["Float"]>;
  status?: Maybe<EventStatus>;
};

export type CustomerSalesLinks = {
  __typename?: "CustomerSalesLinks";
  customerLogo?: Maybe<Scalars["String"]>;
  customerSalesLinks?: Maybe<Array<CustomerSalesLink>>;
};

export enum EventStatus {
  Active = "ACTIVE",
  Closedout = "CLOSEDOUT",
  Complete = "COMPLETE",
  NotPresent = "NOT_PRESENT",
  Presale = "PRESALE",
  Reconciled = "RECONCILED",
  Scheduled = "SCHEDULED",
  Suspended = "SUSPENDED",
}

export enum EventStatusActions {
  ActivateSale = "ACTIVATE_SALE",
  EndSale = "END_SALE",
  PauseSale = "PAUSE_SALE",
  PresaleChecklistComplete = "PRESALE_CHECKLIST_COMPLETE",
  ResetSale = "RESET_SALE",
  ReverseComplete = "REVERSE_COMPLETE",
  RunStatsComplete = "RUN_STATS_COMPLETE",
  RunStatsReset = "RUN_STATS_RESET",
  SaleCloseout = "SALE_CLOSEOUT",
  StartupChecklistComplete = "STARTUP_CHECKLIST_COMPLETE",
}

export type Facility = {
  __typename?: "Facility";
  auctionCompany: Scalars["String"];
  auctionEvent?: Maybe<AuctionEvent>;
  avPort?: Maybe<Scalars["Float"]>;
  bidPort: Scalars["Float"];
  facilityId: Scalars["Float"];
  facilityName: Scalars["String"];
  featureCollectionOverride?: Maybe<Scalars["String"]>;
  flavorGroup?: Maybe<Scalars["String"]>;
  lane?: Maybe<Scalars["String"]>;
  laneFullName?: Maybe<Scalars["String"]>;
  marketplace?: Maybe<Scalars["String"]>;
  masterPort: Scalars["Float"];
  updatePort: Scalars["Float"];
};

export type Flavor = {
  __typename?: "Flavor";
  allowPreview?: Maybe<Scalars["Boolean"]>;
  flavor: Scalars["String"];
  flavorDescription: Scalars["String"];
  flavorGroupCollections: Array<FlavorGroupCollection>;
};

export type FlavorGroup = {
  __typename?: "FlavorGroup";
  auctionCompanies: Array<AuctionCompany>;
  flavorGroup: Scalars["String"];
  flavorGroupCollections?: Maybe<Array<FlavorGroupCollection>>;
};

export type FlavorGroupCollection = {
  __typename?: "FlavorGroupCollection";
  deviceType: Scalars["String"];
  flavor: Flavor;
  flavorGroup: FlavorGroup;
  flavorGroupName: Scalars["String"];
  flavorName: Scalars["String"];
  isDefault: Scalars["String"];
  role: Scalars["String"];
};

export type HighestPlayerVersion = {
  __typename?: "HighestPlayerVersion";
  highestPlayerVersion?: Maybe<Scalars["String"]>;
};

export type InventoryCount = {
  __typename?: "InventoryCount";
  count: Scalars["Float"];
  eventId: Scalars["Float"];
  status: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAttendee: OlrServicesResponses;
  attend: Scalars["String"];
  changeAvConnection: OlrServicesResponses;
  customerAttend: Scalars["String"];
  getSessionToken: SessionToken;
  multiAttend: Scalars["String"];
  setAvPublisherAction: MutationResponse;
  setAvPublisherGain: MutationResponse;
  updateEventDates: UpdateEventDatesResponses;
  updateEventStatus: UpdateEventStatusResponses;
  updatePortStatus: MutationResponse;
  updatePortStatusByPortNumber: MutationResponse;
};

export type MutationAddAttendeeArgs = {
  clientEventId: Scalars["String"];
  type: AttendeeTypeEnum;
  username?: InputMaybe<Scalars["String"]>;
};

export type MutationAttendArgs = {
  ahco: Scalars["String"];
  devOverride?: InputMaybe<Scalars["String"]>;
  eventId: Scalars["Float"];
  flavorOverride?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<Scalars["String"]>;
  type: AttendeeTypeEnum;
  username?: InputMaybe<Scalars["String"]>;
};

export type MutationChangeAvConnectionArgs = {
  facilityName: Scalars["String"];
  type: AvConnectionTypeEnum;
};

export type MutationCustomerAttendArgs = {
  ahco: Scalars["String"];
  eventId: Scalars["Float"];
  password?: InputMaybe<Scalars["String"]>;
  type: AttendeeTypeEnum;
  username?: InputMaybe<Scalars["String"]>;
};

export type MutationGetSessionTokenArgs = {
  userInput: UserInput;
};

export type MutationMultiAttendArgs = {
  ahco?: InputMaybe<Scalars["String"]>;
  clientAuctionIds: Array<Scalars["String"]>;
  clientEventId: Array<Scalars["String"]>;
  devOverride?: InputMaybe<Scalars["String"]>;
  flavorOverride?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<Scalars["String"]>;
  type: AttendeeTypeEnum;
};

export type MutationSetAvPublisherActionArgs = {
  action: StreamActionEnum;
  avPort: Scalars["Float"];
};

export type MutationSetAvPublisherGainArgs = {
  avPort: Scalars["Float"];
  gain: Scalars["Float"];
};

export type MutationUpdateEventDatesArgs = {
  endDateTime?: InputMaybe<Scalars["DateTime"]>;
  eventId: Scalars["Float"];
  startDateTime?: InputMaybe<Scalars["DateTime"]>;
};

export type MutationUpdateEventStatusArgs = {
  action: EventStatusActions;
  eventIds: Array<Scalars["Float"]>;
};

export type MutationUpdatePortStatusArgs = {
  eventId: Scalars["Float"];
  status: PortActionEnum;
  type?: InputMaybe<PortTypeEnum>;
};

export type MutationUpdatePortStatusByPortNumberArgs = {
  portNumber: Scalars["Float"];
  status: PortActionEnum;
  type?: InputMaybe<PortTypeEnum>;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  success: Scalars["Boolean"];
};

export type OlrServicesResponse = {
  __typename?: "OLRServicesResponse";
  id: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  request: Scalars["String"];
  status: Scalars["String"];
};

export type OlrServicesResponseWrapper = {
  __typename?: "OLRServicesResponseWrapper";
  response: OlrServicesResponse;
};

export type OlrServicesResponses = {
  __typename?: "OLRServicesResponses";
  responses: Array<OlrServicesResponseWrapper>;
};

export enum Permission {
  AttendeesLaunch = "ATTENDEES_LAUNCH",
  AttendeesRead = "ATTENDEES_READ",
  AvStreamsRead = "AV_STREAMS_READ",
  AvStreamsStop = "AV_STREAMS_STOP",
  AvStreamsUpdate = "AV_STREAMS_UPDATE",
  CompaniesRead = "COMPANIES_READ",
  CumulativeStatsRead = "CUMULATIVE_STATS_READ",
  EventsRead = "EVENTS_READ",
  EventsUpdate = "EVENTS_UPDATE",
  FacilitiesRead = "FACILITIES_READ",
  PortsRead = "PORTS_READ",
  PortsStop = "PORTS_STOP",
  PortsUpdate = "PORTS_UPDATE",
  ToolsRead = "TOOLS_READ",
}

export type Port = {
  __typename?: "Port";
  portNumber: Scalars["Float"];
  status: PortStatusEnum;
  type: PortTypeEnum;
};

export enum PortActionEnum {
  Bounce = "BOUNCE",
  Start = "START",
  Stop = "STOP",
}

export type PortStatus = {
  __typename?: "PortStatus";
  ports: Array<Port>;
  updatedOn?: Maybe<Scalars["Float"]>;
};

export enum PortStatusEnum {
  Down = "DOWN",
  Up = "UP",
}

export enum PortTypeEnum {
  Bid = "BID",
  Master = "MASTER",
  Update = "UPDATE",
}

export type Query = {
  __typename?: "Query";
  appInfo: AppInfo;
  auctionAttendees: Array<AuctionAttendee>;
  /** Get active companies */
  auctionCompanies: Array<AuctionCompany>;
  auctionEvents: Array<AuctionEvent>;
  auctionHealthStatus: AuctionHealthStatusResponse;
  avPublishers: Array<AvPublisher>;
  avStreamNamebyAudioPort: Scalars["String"];
  avStreams: Array<AvStream>;
  cumulativeStats: CumulativeStatsDto;
  customerSalesLinksByAhco: CustomerSalesLinks;
  facilities: Array<Facility>;
  flavors: Array<Flavor>;
  getPortStatus: PortStatus;
  getUser: User;
  highestPlayerVersion: HighestPlayerVersion;
  loggedInAttendeeCounts: Array<AttendeeCount>;
};

export type QueryAuctionAttendeesArgs = {
  clientAttendeeId?: InputMaybe<Scalars["String"]>;
  creditLimit?: InputMaybe<Scalars["Float"]>;
  eventId: Scalars["Float"];
  id?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  sellerId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<AttendeeTypeEnum>;
  warranty?: InputMaybe<Scalars["String"]>;
};

export type QueryAuctionCompaniesArgs = {
  auctionCompany?: InputMaybe<Scalars["String"]>;
  customerLogo?: InputMaybe<Scalars["String"]>;
  flavorGroupName?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Float"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  showCustomerSalesLinks?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryAuctionEventsArgs = {
  auctionCompany?: InputMaybe<Scalars["String"]>;
  avPort?: InputMaybe<Scalars["Float"]>;
  bidPort?: InputMaybe<Scalars["Float"]>;
  clientAuctionId?: InputMaybe<Scalars["String"]>;
  clientEventId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["Float"]>;
  facilityId?: InputMaybe<Scalars["Float"]>;
  facilityName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Float"]>;
  inventoryTable?: InputMaybe<Scalars["String"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]>;
  lotsClerked?: InputMaybe<Scalars["Float"]>;
  masterPort?: InputMaybe<Scalars["Float"]>;
  startTime?: InputMaybe<Scalars["Float"]>;
  status?: InputMaybe<EventStatus>;
  updatePort?: InputMaybe<Scalars["Float"]>;
};

export type QueryAvPublishersArgs = {
  action?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  gain?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["Float"]>;
  info?: InputMaybe<Scalars["String"]>;
  lastModified?: InputMaybe<Scalars["Float"]>;
  machineIp?: InputMaybe<Scalars["String"]>;
  restartTime?: InputMaybe<Scalars["Float"]>;
  startTime?: InputMaybe<Scalars["Float"]>;
  status?: InputMaybe<AvStreamStatus>;
  statusInfo?: InputMaybe<Scalars["String"]>;
  streamId?: InputMaybe<Scalars["Float"]>;
};

export type QueryAvStreamNamebyAudioPortArgs = {
  audioPort: Scalars["Float"];
};

export type QueryAvStreamsArgs = {
  audioPort?: InputMaybe<Scalars["Float"]>;
  connectionId?: InputMaybe<Scalars["Float"]>;
  encoderId?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  streamType?: InputMaybe<StreamTypeEnum>;
};

export type QueryCumulativeStatsArgs = {
  ahco?: InputMaybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  limit?: InputMaybe<Scalars["Float"]>;
  startDate: Scalars["DateTime"];
};

export type QueryCustomerSalesLinksByAhcoArgs = {
  ahco: Scalars["String"];
};

export type QueryFacilitiesArgs = {
  auctionCompany?: InputMaybe<Scalars["String"]>;
  avPort?: InputMaybe<Scalars["Float"]>;
  bidPort?: InputMaybe<Scalars["Float"]>;
  facilityId?: InputMaybe<Scalars["Float"]>;
  facilityName?: InputMaybe<Scalars["String"]>;
  featureCollectionOverride?: InputMaybe<Scalars["String"]>;
  flavorGroup?: InputMaybe<Scalars["String"]>;
  lane?: InputMaybe<Scalars["String"]>;
  laneFullName?: InputMaybe<Scalars["String"]>;
  marketplace?: InputMaybe<Scalars["String"]>;
  masterPort?: InputMaybe<Scalars["Float"]>;
  updatePort?: InputMaybe<Scalars["Float"]>;
};

export type QueryFlavorsArgs = {
  ahco?: InputMaybe<Scalars["String"]>;
  allowPreview?: InputMaybe<Scalars["Boolean"]>;
  flavor?: InputMaybe<Scalars["String"]>;
  flavorDescription?: InputMaybe<Scalars["String"]>;
};

export type QueryLoggedInAttendeeCountsArgs = {
  eventId?: InputMaybe<Scalars["Float"]>;
  eventIds?: InputMaybe<Array<Scalars["Float"]>>;
  type?: InputMaybe<AttendeeTypeEnum>;
};

export type SessionToken = {
  __typename?: "SessionToken";
  token: Scalars["String"];
};

export enum StreamActionEnum {
  Reload = "RELOAD",
  ReloadAudio = "RELOAD_AUDIO",
  ReloadVideo = "RELOAD_VIDEO",
  Start = "START",
  Stop = "STOP",
}

export enum StreamTypeEnum {
  AudioOnly = "AUDIO_ONLY",
  AudioVideo = "AUDIO_VIDEO",
  VideoOnly = "VIDEO_ONLY",
}

export type Subscription = {
  __typename?: "Subscription";
  portStatusesUpdated: PortStatus;
};

export type UpdateEventDatesResponse = {
  __typename?: "UpdateEventDatesResponse";
  id: Scalars["String"];
  request: Scalars["String"];
  status: Scalars["String"];
};

export type UpdateEventDatesResponseWrapper = {
  __typename?: "UpdateEventDatesResponseWrapper";
  response: UpdateEventDatesResponse;
};

export type UpdateEventDatesResponses = {
  __typename?: "UpdateEventDatesResponses";
  responses: Array<UpdateEventDatesResponseWrapper>;
};

export type UpdateEventStatusResponse = {
  __typename?: "UpdateEventStatusResponse";
  id: Scalars["Float"];
  message?: Maybe<Scalars["String"]>;
  request: Scalars["String"];
  status: Scalars["String"];
};

export type UpdateEventStatusResponseWrapper = {
  __typename?: "UpdateEventStatusResponseWrapper";
  response: UpdateEventStatusResponse;
};

export type UpdateEventStatusResponses = {
  __typename?: "UpdateEventStatusResponses";
  responses: Array<UpdateEventStatusResponseWrapper>;
};

export type User = {
  __typename?: "User";
  permissions: Array<Permission>;
  username: Scalars["String"];
};

export type UserInput = {
  password: Scalars["String"];
  username: Scalars["String"];
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AppInfo: ResolverTypeWrapper<AppInfo>;
  AttendeeCount: ResolverTypeWrapper<AttendeeCount>;
  AttendeeTypeEnum: AttendeeTypeEnum;
  AuctionAttendee: ResolverTypeWrapper<AuctionAttendee>;
  AuctionCompany: ResolverTypeWrapper<AuctionCompany>;
  AuctionEvent: ResolverTypeWrapper<AuctionEvent>;
  AuctionHealthStatusItem: ResolverTypeWrapper<AuctionHealthStatusItem>;
  AuctionHealthStatusResponse: ResolverTypeWrapper<AuctionHealthStatusResponse>;
  AvConnection: ResolverTypeWrapper<AvConnection>;
  AvConnectionTypeEnum: AvConnectionTypeEnum;
  AvEncoderSettings: ResolverTypeWrapper<AvEncoderSettings>;
  AvPublisher: ResolverTypeWrapper<AvPublisher>;
  AvPublisherCamera: ResolverTypeWrapper<AvPublisherCamera>;
  AvPublisherEnvironment: ResolverTypeWrapper<AvPublisherEnvironment>;
  AvPublisherInfo: ResolverTypeWrapper<AvPublisherInfo>;
  AvPublisherMic: ResolverTypeWrapper<AvPublisherMic>;
  AvPublisherStatistics: ResolverTypeWrapper<AvPublisherStatistics>;
  AvPublisherStatus: ResolverTypeWrapper<AvPublisherStatus>;
  AvPublisherStatusActivity: ResolverTypeWrapper<AvPublisherStatusActivity>;
  AvStream: ResolverTypeWrapper<AvStream>;
  AvStreamStatus: AvStreamStatus;
  Boolean: ResolverTypeWrapper<Scalars["Boolean"]>;
  CumulativeStatDto: ResolverTypeWrapper<CumulativeStatDto>;
  CumulativeStatsDto: ResolverTypeWrapper<CumulativeStatsDto>;
  CumulativeStatsTotalsDto: ResolverTypeWrapper<CumulativeStatsTotalsDto>;
  CustomerSalesLink: ResolverTypeWrapper<CustomerSalesLink>;
  CustomerSalesLinks: ResolverTypeWrapper<CustomerSalesLinks>;
  DateTime: ResolverTypeWrapper<Scalars["DateTime"]>;
  EventStatus: EventStatus;
  EventStatusActions: EventStatusActions;
  Facility: ResolverTypeWrapper<Facility>;
  Flavor: ResolverTypeWrapper<Flavor>;
  FlavorGroup: ResolverTypeWrapper<FlavorGroup>;
  FlavorGroupCollection: ResolverTypeWrapper<FlavorGroupCollection>;
  Float: ResolverTypeWrapper<Scalars["Float"]>;
  HighestPlayerVersion: ResolverTypeWrapper<HighestPlayerVersion>;
  InventoryCount: ResolverTypeWrapper<InventoryCount>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationResponse: ResolverTypeWrapper<MutationResponse>;
  OLRServicesResponse: ResolverTypeWrapper<OlrServicesResponse>;
  OLRServicesResponseWrapper: ResolverTypeWrapper<OlrServicesResponseWrapper>;
  OLRServicesResponses: ResolverTypeWrapper<OlrServicesResponses>;
  Permission: Permission;
  Port: ResolverTypeWrapper<Port>;
  PortActionEnum: PortActionEnum;
  PortStatus: ResolverTypeWrapper<PortStatus>;
  PortStatusEnum: PortStatusEnum;
  PortTypeEnum: PortTypeEnum;
  Query: ResolverTypeWrapper<{}>;
  SessionToken: ResolverTypeWrapper<SessionToken>;
  StreamActionEnum: StreamActionEnum;
  StreamTypeEnum: StreamTypeEnum;
  String: ResolverTypeWrapper<Scalars["String"]>;
  Subscription: ResolverTypeWrapper<{}>;
  UpdateEventDatesResponse: ResolverTypeWrapper<UpdateEventDatesResponse>;
  UpdateEventDatesResponseWrapper: ResolverTypeWrapper<UpdateEventDatesResponseWrapper>;
  UpdateEventDatesResponses: ResolverTypeWrapper<UpdateEventDatesResponses>;
  UpdateEventStatusResponse: ResolverTypeWrapper<UpdateEventStatusResponse>;
  UpdateEventStatusResponseWrapper: ResolverTypeWrapper<UpdateEventStatusResponseWrapper>;
  UpdateEventStatusResponses: ResolverTypeWrapper<UpdateEventStatusResponses>;
  User: ResolverTypeWrapper<User>;
  UserInput: UserInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AppInfo: AppInfo;
  AttendeeCount: AttendeeCount;
  AuctionAttendee: AuctionAttendee;
  AuctionCompany: AuctionCompany;
  AuctionEvent: AuctionEvent;
  AuctionHealthStatusItem: AuctionHealthStatusItem;
  AuctionHealthStatusResponse: AuctionHealthStatusResponse;
  AvConnection: AvConnection;
  AvEncoderSettings: AvEncoderSettings;
  AvPublisher: AvPublisher;
  AvPublisherCamera: AvPublisherCamera;
  AvPublisherEnvironment: AvPublisherEnvironment;
  AvPublisherInfo: AvPublisherInfo;
  AvPublisherMic: AvPublisherMic;
  AvPublisherStatistics: AvPublisherStatistics;
  AvPublisherStatus: AvPublisherStatus;
  AvPublisherStatusActivity: AvPublisherStatusActivity;
  AvStream: AvStream;
  Boolean: Scalars["Boolean"];
  CumulativeStatDto: CumulativeStatDto;
  CumulativeStatsDto: CumulativeStatsDto;
  CumulativeStatsTotalsDto: CumulativeStatsTotalsDto;
  CustomerSalesLink: CustomerSalesLink;
  CustomerSalesLinks: CustomerSalesLinks;
  DateTime: Scalars["DateTime"];
  Facility: Facility;
  Flavor: Flavor;
  FlavorGroup: FlavorGroup;
  FlavorGroupCollection: FlavorGroupCollection;
  Float: Scalars["Float"];
  HighestPlayerVersion: HighestPlayerVersion;
  InventoryCount: InventoryCount;
  Mutation: {};
  MutationResponse: MutationResponse;
  OLRServicesResponse: OlrServicesResponse;
  OLRServicesResponseWrapper: OlrServicesResponseWrapper;
  OLRServicesResponses: OlrServicesResponses;
  Port: Port;
  PortStatus: PortStatus;
  Query: {};
  SessionToken: SessionToken;
  String: Scalars["String"];
  Subscription: {};
  UpdateEventDatesResponse: UpdateEventDatesResponse;
  UpdateEventDatesResponseWrapper: UpdateEventDatesResponseWrapper;
  UpdateEventDatesResponses: UpdateEventDatesResponses;
  UpdateEventStatusResponse: UpdateEventStatusResponse;
  UpdateEventStatusResponseWrapper: UpdateEventStatusResponseWrapper;
  UpdateEventStatusResponses: UpdateEventStatusResponses;
  User: User;
  UserInput: UserInput;
};

export type AppInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AppInfo"] = ResolversParentTypes["AppInfo"]
> = {
  envName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AttendeeCount"] = ResolversParentTypes["AttendeeCount"]
> = {
  count?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes["AttendeeTypeEnum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuctionAttendeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AuctionAttendee"] = ResolversParentTypes["AuctionAttendee"]
> = {
  clientAttendeeId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  creditLimit?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  eventId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  sellerId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes["AttendeeTypeEnum"], ParentType, ContextType>;
  warranty?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuctionCompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AuctionCompany"] = ResolversParentTypes["AuctionCompany"]
> = {
  auctionCompany?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  customerLogo?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  flavorGroup?: Resolver<Maybe<ResolversTypes["FlavorGroup"]>, ParentType, ContextType>;
  flavorGroupName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  showCustomerSalesLinks?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuctionEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AuctionEvent"] = ResolversParentTypes["AuctionEvent"]
> = {
  auctionCompany?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  avPort?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  bidPort?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  clientAuctionId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  clientEventId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  facilityId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  facilityName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  inventoryCounts?: Resolver<
    Maybe<Array<ResolversTypes["InventoryCount"]>>,
    ParentType,
    ContextType
  >;
  inventoryTable?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  isPaused?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  lotsClerked?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  masterPort?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["EventStatus"], ParentType, ContextType>;
  updatePort?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuctionHealthStatusItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AuctionHealthStatusItem"] = ResolversParentTypes["AuctionHealthStatusItem"]
> = {
  ahco?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  clientEventId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  eventStatus?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  facilityName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  opsStatus?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  warnings?: Resolver<Maybe<Array<ResolversTypes["String"]>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuctionHealthStatusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AuctionHealthStatusResponse"] = ResolversParentTypes["AuctionHealthStatusResponse"]
> = {
  items?: Resolver<Array<ResolversTypes["AuctionHealthStatusItem"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvConnection"] = ResolversParentTypes["AvConnection"]
> = {
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  edgeUrl?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvEncoderSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvEncoderSettings"] = ResolversParentTypes["AvEncoderSettings"]
> = {
  audioBitrate?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  avStreams?: Resolver<Maybe<Array<ResolversTypes["AvStream"]>>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  encoderName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  videoFps?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisher"] = ResolversParentTypes["AvPublisher"]
> = {
  action?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  avStream?: Resolver<ResolversTypes["AvStream"], ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  gain?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  infoRaw?: Resolver<Maybe<ResolversTypes["AvPublisherInfo"]>, ParentType, ContextType>;
  lastModified?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  machineIp?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  restartTime?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  statisticsRaw?: Resolver<Maybe<ResolversTypes["AvPublisherStatistics"]>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes["AvStreamStatus"], ParentType, ContextType>;
  statusInfo?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  statusRaw?: Resolver<Maybe<ResolversTypes["AvPublisherStatus"]>, ParentType, ContextType>;
  streamId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherCameraResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherCamera"] = ResolversParentTypes["AvPublisherCamera"]
> = {
  bandwidth?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  codec?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  fps?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  keyframeinterval?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  muted?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  quality?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherEnvironmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherEnvironment"] = ResolversParentTypes["AvPublisherEnvironment"]
> = {
  debug?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  os?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  playerversion?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherInfo"] = ResolversParentTypes["AvPublisherInfo"]
> = {
  camera?: Resolver<Maybe<ResolversTypes["AvPublisherCamera"]>, ParentType, ContextType>;
  env?: Resolver<Maybe<ResolversTypes["AvPublisherEnvironment"]>, ParentType, ContextType>;
  mic?: Resolver<Maybe<ResolversTypes["AvPublisherMic"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherMicResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherMic"] = ResolversParentTypes["AvPublisherMic"]
> = {
  codec?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  framesperpacket?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  muted?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  quality?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherStatisticsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherStatistics"] = ResolversParentTypes["AvPublisherStatistics"]
> = {
  audioBitrate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  bitrate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  protocol?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  videoBitrate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  videoFramerate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  videoHeight?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  videoWidth?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherStatus"] = ResolversParentTypes["AvPublisherStatus"]
> = {
  activity?: Resolver<Maybe<ResolversTypes["AvPublisherStatusActivity"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["AvStreamStatus"]>, ParentType, ContextType>;
  statusinfo?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvPublisherStatusActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvPublisherStatusActivity"] = ResolversParentTypes["AvPublisherStatusActivity"]
> = {
  camera?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  mic?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AvStream"] = ResolversParentTypes["AvStream"]
> = {
  audioPort?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  avConnection?: Resolver<ResolversTypes["AvConnection"], ParentType, ContextType>;
  avConnectionAlternate?: Resolver<Maybe<ResolversTypes["AvConnection"]>, ParentType, ContextType>;
  avConnectionBackup?: Resolver<Maybe<ResolversTypes["AvConnection"]>, ParentType, ContextType>;
  avConnectionPrimary?: Resolver<Maybe<ResolversTypes["AvConnection"]>, ParentType, ContextType>;
  avEncoderSettings?: Resolver<ResolversTypes["AvEncoderSettings"], ParentType, ContextType>;
  avPublishers?: Resolver<Maybe<Array<ResolversTypes["AvPublisher"]>>, ParentType, ContextType>;
  connectionId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  encoderId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  streamType?: Resolver<ResolversTypes["StreamTypeEnum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CumulativeStatDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CumulativeStatDto"] = ResolversParentTypes["CumulativeStatDto"]
> = {
  acceptedBids?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  acceptedNetBids?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  auctionName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  averageItemOlr?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  averagePerItem?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  biddersAttended?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  difItemsNetAccepted?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  lotsClerked?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  netPurchasedItems?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  netWithoutBid?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  percentageOlrBid?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  percentageOlrRunnerup?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  percentageOlrSold?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  percentageSalesOlr?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  ringmanName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  runningTime?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  secondHighest?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  soldToNet?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  totalCars?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  totalLoginDuration?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  totalNetSales?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  totalSales?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  totalSold?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CumulativeStatsDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CumulativeStatsDto"] = ResolversParentTypes["CumulativeStatsDto"]
> = {
  cumulativeStats?: Resolver<
    Maybe<Array<ResolversTypes["CumulativeStatDto"]>>,
    ParentType,
    ContextType
  >;
  totals?: Resolver<ResolversTypes["CumulativeStatsTotalsDto"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CumulativeStatsTotalsDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CumulativeStatsTotalsDto"] = ResolversParentTypes["CumulativeStatsTotalsDto"]
> = {
  totalAcceptedBidsOlr?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalAttendeesOlr?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalAttendeesOlrBid?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalAttendeesOlrWithoutBid?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalAttendeesOlrWon?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalAveragePerItem?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalAveragePerItemOlr?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalItems?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalLoginTime?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalLotsClerked?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalPercentageOlrBid?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalPercentageOlrRunnerup?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalPercentageOlrSold?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalPercentageSalesOlr?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalRunningTime?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalSales?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalSalesOlr?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalSold?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalSoldOlr?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalSoldOlrBid?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  totalSoldRunnerup?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSalesLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CustomerSalesLink"] = ResolversParentTypes["CustomerSalesLink"]
> = {
  ahco?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  clientEventId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  facilityName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  laneFullName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["EventStatus"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSalesLinksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CustomerSalesLinks"] = ResolversParentTypes["CustomerSalesLinks"]
> = {
  customerLogo?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  customerSalesLinks?: Resolver<
    Maybe<Array<ResolversTypes["CustomerSalesLink"]>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["DateTime"], any> {
  name: "DateTime";
}

export type FacilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Facility"] = ResolversParentTypes["Facility"]
> = {
  auctionCompany?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  auctionEvent?: Resolver<Maybe<ResolversTypes["AuctionEvent"]>, ParentType, ContextType>;
  avPort?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  bidPort?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  facilityId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  facilityName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  featureCollectionOverride?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  flavorGroup?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  lane?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  laneFullName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  marketplace?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  masterPort?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  updatePort?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlavorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Flavor"] = ResolversParentTypes["Flavor"]
> = {
  allowPreview?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  flavor?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  flavorDescription?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  flavorGroupCollections?: Resolver<
    Array<ResolversTypes["FlavorGroupCollection"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlavorGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["FlavorGroup"] = ResolversParentTypes["FlavorGroup"]
> = {
  auctionCompanies?: Resolver<Array<ResolversTypes["AuctionCompany"]>, ParentType, ContextType>;
  flavorGroup?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  flavorGroupCollections?: Resolver<
    Maybe<Array<ResolversTypes["FlavorGroupCollection"]>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlavorGroupCollectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["FlavorGroupCollection"] = ResolversParentTypes["FlavorGroupCollection"]
> = {
  deviceType?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  flavor?: Resolver<ResolversTypes["Flavor"], ParentType, ContextType>;
  flavorGroup?: Resolver<ResolversTypes["FlavorGroup"], ParentType, ContextType>;
  flavorGroupName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  flavorName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  role?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HighestPlayerVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["HighestPlayerVersion"] = ResolversParentTypes["HighestPlayerVersion"]
> = {
  highestPlayerVersion?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["InventoryCount"] = ResolversParentTypes["InventoryCount"]
> = {
  count?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  eventId?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Mutation"] = ResolversParentTypes["Mutation"]
> = {
  addAttendee?: Resolver<
    ResolversTypes["OLRServicesResponses"],
    ParentType,
    ContextType,
    RequireFields<MutationAddAttendeeArgs, "clientEventId" | "type">
  >;
  attend?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    RequireFields<MutationAttendArgs, "ahco" | "eventId" | "type">
  >;
  changeAvConnection?: Resolver<
    ResolversTypes["OLRServicesResponses"],
    ParentType,
    ContextType,
    RequireFields<MutationChangeAvConnectionArgs, "facilityName" | "type">
  >;
  customerAttend?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    RequireFields<MutationCustomerAttendArgs, "ahco" | "eventId" | "type">
  >;
  getSessionToken?: Resolver<
    ResolversTypes["SessionToken"],
    ParentType,
    ContextType,
    RequireFields<MutationGetSessionTokenArgs, "userInput">
  >;
  multiAttend?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    RequireFields<MutationMultiAttendArgs, "clientAuctionIds" | "clientEventId" | "type">
  >;
  setAvPublisherAction?: Resolver<
    ResolversTypes["MutationResponse"],
    ParentType,
    ContextType,
    RequireFields<MutationSetAvPublisherActionArgs, "action" | "avPort">
  >;
  setAvPublisherGain?: Resolver<
    ResolversTypes["MutationResponse"],
    ParentType,
    ContextType,
    RequireFields<MutationSetAvPublisherGainArgs, "avPort" | "gain">
  >;
  updateEventDates?: Resolver<
    ResolversTypes["UpdateEventDatesResponses"],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEventDatesArgs, "eventId">
  >;
  updateEventStatus?: Resolver<
    ResolversTypes["UpdateEventStatusResponses"],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEventStatusArgs, "action" | "eventIds">
  >;
  updatePortStatus?: Resolver<
    ResolversTypes["MutationResponse"],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePortStatusArgs, "eventId" | "status">
  >;
  updatePortStatusByPortNumber?: Resolver<
    ResolversTypes["MutationResponse"],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePortStatusByPortNumberArgs, "portNumber" | "status">
  >;
};

export type MutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["MutationResponse"] = ResolversParentTypes["MutationResponse"]
> = {
  success?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OlrServicesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["OLRServicesResponse"] = ResolversParentTypes["OLRServicesResponse"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OlrServicesResponseWrapperResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["OLRServicesResponseWrapper"] = ResolversParentTypes["OLRServicesResponseWrapper"]
> = {
  response?: Resolver<ResolversTypes["OLRServicesResponse"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OlrServicesResponsesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["OLRServicesResponses"] = ResolversParentTypes["OLRServicesResponses"]
> = {
  responses?: Resolver<
    Array<ResolversTypes["OLRServicesResponseWrapper"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Port"] = ResolversParentTypes["Port"]
> = {
  portNumber?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["PortStatusEnum"], ParentType, ContextType>;
  type?: Resolver<ResolversTypes["PortTypeEnum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["PortStatus"] = ResolversParentTypes["PortStatus"]
> = {
  ports?: Resolver<Array<ResolversTypes["Port"]>, ParentType, ContextType>;
  updatedOn?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Query"] = ResolversParentTypes["Query"]
> = {
  appInfo?: Resolver<ResolversTypes["AppInfo"], ParentType, ContextType>;
  auctionAttendees?: Resolver<
    Array<ResolversTypes["AuctionAttendee"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAuctionAttendeesArgs, "eventId">
  >;
  auctionCompanies?: Resolver<
    Array<ResolversTypes["AuctionCompany"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAuctionCompaniesArgs, "isActive">
  >;
  auctionEvents?: Resolver<
    Array<ResolversTypes["AuctionEvent"]>,
    ParentType,
    ContextType,
    Partial<QueryAuctionEventsArgs>
  >;
  auctionHealthStatus?: Resolver<
    ResolversTypes["AuctionHealthStatusResponse"],
    ParentType,
    ContextType
  >;
  avPublishers?: Resolver<
    Array<ResolversTypes["AvPublisher"]>,
    ParentType,
    ContextType,
    Partial<QueryAvPublishersArgs>
  >;
  avStreamNamebyAudioPort?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType,
    RequireFields<QueryAvStreamNamebyAudioPortArgs, "audioPort">
  >;
  avStreams?: Resolver<
    Array<ResolversTypes["AvStream"]>,
    ParentType,
    ContextType,
    Partial<QueryAvStreamsArgs>
  >;
  cumulativeStats?: Resolver<
    ResolversTypes["CumulativeStatsDto"],
    ParentType,
    ContextType,
    RequireFields<QueryCumulativeStatsArgs, "endDate" | "startDate">
  >;
  customerSalesLinksByAhco?: Resolver<
    ResolversTypes["CustomerSalesLinks"],
    ParentType,
    ContextType,
    RequireFields<QueryCustomerSalesLinksByAhcoArgs, "ahco">
  >;
  facilities?: Resolver<
    Array<ResolversTypes["Facility"]>,
    ParentType,
    ContextType,
    Partial<QueryFacilitiesArgs>
  >;
  flavors?: Resolver<
    Array<ResolversTypes["Flavor"]>,
    ParentType,
    ContextType,
    Partial<QueryFlavorsArgs>
  >;
  getPortStatus?: Resolver<ResolversTypes["PortStatus"], ParentType, ContextType>;
  getUser?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
  highestPlayerVersion?: Resolver<ResolversTypes["HighestPlayerVersion"], ParentType, ContextType>;
  loggedInAttendeeCounts?: Resolver<
    Array<ResolversTypes["AttendeeCount"]>,
    ParentType,
    ContextType,
    Partial<QueryLoggedInAttendeeCountsArgs>
  >;
};

export type SessionTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["SessionToken"] = ResolversParentTypes["SessionToken"]
> = {
  token?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Subscription"] = ResolversParentTypes["Subscription"]
> = {
  portStatusesUpdated?: SubscriptionResolver<
    ResolversTypes["PortStatus"],
    "portStatusesUpdated",
    ParentType,
    ContextType
  >;
};

export type UpdateEventDatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateEventDatesResponse"] = ResolversParentTypes["UpdateEventDatesResponse"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  request?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventDatesResponseWrapperResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateEventDatesResponseWrapper"] = ResolversParentTypes["UpdateEventDatesResponseWrapper"]
> = {
  response?: Resolver<ResolversTypes["UpdateEventDatesResponse"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventDatesResponsesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateEventDatesResponses"] = ResolversParentTypes["UpdateEventDatesResponses"]
> = {
  responses?: Resolver<
    Array<ResolversTypes["UpdateEventDatesResponseWrapper"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventStatusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateEventStatusResponse"] = ResolversParentTypes["UpdateEventStatusResponse"]
> = {
  id?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventStatusResponseWrapperResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateEventStatusResponseWrapper"] = ResolversParentTypes["UpdateEventStatusResponseWrapper"]
> = {
  response?: Resolver<ResolversTypes["UpdateEventStatusResponse"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventStatusResponsesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateEventStatusResponses"] = ResolversParentTypes["UpdateEventStatusResponses"]
> = {
  responses?: Resolver<
    Array<ResolversTypes["UpdateEventStatusResponseWrapper"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["User"] = ResolversParentTypes["User"]
> = {
  permissions?: Resolver<Array<ResolversTypes["Permission"]>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AppInfo?: AppInfoResolvers<ContextType>;
  AttendeeCount?: AttendeeCountResolvers<ContextType>;
  AuctionAttendee?: AuctionAttendeeResolvers<ContextType>;
  AuctionCompany?: AuctionCompanyResolvers<ContextType>;
  AuctionEvent?: AuctionEventResolvers<ContextType>;
  AuctionHealthStatusItem?: AuctionHealthStatusItemResolvers<ContextType>;
  AuctionHealthStatusResponse?: AuctionHealthStatusResponseResolvers<ContextType>;
  AvConnection?: AvConnectionResolvers<ContextType>;
  AvEncoderSettings?: AvEncoderSettingsResolvers<ContextType>;
  AvPublisher?: AvPublisherResolvers<ContextType>;
  AvPublisherCamera?: AvPublisherCameraResolvers<ContextType>;
  AvPublisherEnvironment?: AvPublisherEnvironmentResolvers<ContextType>;
  AvPublisherInfo?: AvPublisherInfoResolvers<ContextType>;
  AvPublisherMic?: AvPublisherMicResolvers<ContextType>;
  AvPublisherStatistics?: AvPublisherStatisticsResolvers<ContextType>;
  AvPublisherStatus?: AvPublisherStatusResolvers<ContextType>;
  AvPublisherStatusActivity?: AvPublisherStatusActivityResolvers<ContextType>;
  AvStream?: AvStreamResolvers<ContextType>;
  CumulativeStatDto?: CumulativeStatDtoResolvers<ContextType>;
  CumulativeStatsDto?: CumulativeStatsDtoResolvers<ContextType>;
  CumulativeStatsTotalsDto?: CumulativeStatsTotalsDtoResolvers<ContextType>;
  CustomerSalesLink?: CustomerSalesLinkResolvers<ContextType>;
  CustomerSalesLinks?: CustomerSalesLinksResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Facility?: FacilityResolvers<ContextType>;
  Flavor?: FlavorResolvers<ContextType>;
  FlavorGroup?: FlavorGroupResolvers<ContextType>;
  FlavorGroupCollection?: FlavorGroupCollectionResolvers<ContextType>;
  HighestPlayerVersion?: HighestPlayerVersionResolvers<ContextType>;
  InventoryCount?: InventoryCountResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationResponse?: MutationResponseResolvers<ContextType>;
  OLRServicesResponse?: OlrServicesResponseResolvers<ContextType>;
  OLRServicesResponseWrapper?: OlrServicesResponseWrapperResolvers<ContextType>;
  OLRServicesResponses?: OlrServicesResponsesResolvers<ContextType>;
  Port?: PortResolvers<ContextType>;
  PortStatus?: PortStatusResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  SessionToken?: SessionTokenResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  UpdateEventDatesResponse?: UpdateEventDatesResponseResolvers<ContextType>;
  UpdateEventDatesResponseWrapper?: UpdateEventDatesResponseWrapperResolvers<ContextType>;
  UpdateEventDatesResponses?: UpdateEventDatesResponsesResolvers<ContextType>;
  UpdateEventStatusResponse?: UpdateEventStatusResponseResolvers<ContextType>;
  UpdateEventStatusResponseWrapper?: UpdateEventStatusResponseWrapperResolvers<ContextType>;
  UpdateEventStatusResponses?: UpdateEventStatusResponsesResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
};

export type AttendMutationVariables = Exact<{
  ahco: Scalars["String"];
  eventId: Scalars["Float"];
  type: AttendeeTypeEnum;
  flavorOverride?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
  devOverride?: InputMaybe<Scalars["String"]>;
}>;

export type AttendMutation = { __typename?: "Mutation"; attend: string };

export type ChangeAvConnectionMutationVariables = Exact<{
  facilityName: Scalars["String"];
  type: AvConnectionTypeEnum;
}>;

export type ChangeAvConnectionMutation = {
  __typename?: "Mutation";
  changeAvConnection: {
    __typename?: "OLRServicesResponses";
    responses: Array<{
      __typename?: "OLRServicesResponseWrapper";
      response: {
        __typename?: "OLRServicesResponse";
        id: string;
        status: string;
        request: string;
        message?: string | null;
      };
    }>;
  };
};

export type CustomerAttendMutationVariables = Exact<{
  ahco: Scalars["String"];
  eventId: Scalars["Float"];
  type: AttendeeTypeEnum;
  username?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
}>;

export type CustomerAttendMutation = { __typename?: "Mutation"; customerAttend: string };

export type GetAuctionHealthStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuctionHealthStatusQuery = {
  __typename?: "Query";
  auctionHealthStatus: {
    __typename?: "AuctionHealthStatusResponse";
    items: Array<{
      __typename?: "AuctionHealthStatusItem";
      ahco: string;
      clientEventId: string;
      eventStatus: string;
      facilityName: string;
      opsStatus: string;
      warnings?: Array<string> | null;
    }>;
  };
};

export type LoginMutationVariables = Exact<{
  userInput: UserInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  getSessionToken: { __typename?: "SessionToken"; token: string };
};

export type MultiAttendMutationVariables = Exact<{
  ahco?: InputMaybe<Scalars["String"]>;
  flavorOverride?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<Scalars["String"]>;
  devOverride?: InputMaybe<Scalars["String"]>;
  clientEventId: Array<Scalars["String"]> | Scalars["String"];
  type: AttendeeTypeEnum;
  clientAuctionIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type MultiAttendMutation = { __typename?: "Mutation"; multiAttend: string };

export type SetAvPublisherActionMutationVariables = Exact<{
  action: StreamActionEnum;
  avPort: Scalars["Float"];
}>;

export type SetAvPublisherActionMutation = {
  __typename?: "Mutation";
  setAvPublisherAction: { __typename?: "MutationResponse"; success: boolean };
};

export type SetAvPublisherGainMutationVariables = Exact<{
  avPort: Scalars["Float"];
  gain: Scalars["Float"];
}>;

export type SetAvPublisherGainMutation = {
  __typename?: "Mutation";
  setAvPublisherGain: { __typename?: "MutationResponse"; success: boolean };
};

export type UpdateEventDatesMutationVariables = Exact<{
  eventId: Scalars["Float"];
  startDateTime?: InputMaybe<Scalars["DateTime"]>;
  endDateTime?: InputMaybe<Scalars["DateTime"]>;
}>;

export type UpdateEventDatesMutation = {
  __typename?: "Mutation";
  updateEventDates: {
    __typename?: "UpdateEventDatesResponses";
    responses: Array<{
      __typename?: "UpdateEventDatesResponseWrapper";
      response: {
        __typename?: "UpdateEventDatesResponse";
        id: string;
        status: string;
        request: string;
      };
    }>;
  };
};

export type UpdateEventStatusMutationVariables = Exact<{
  eventIds: Array<Scalars["Float"]> | Scalars["Float"];
  action: EventStatusActions;
}>;

export type UpdateEventStatusMutation = {
  __typename?: "Mutation";
  updateEventStatus: {
    __typename?: "UpdateEventStatusResponses";
    responses: Array<{
      __typename?: "UpdateEventStatusResponseWrapper";
      response: {
        __typename?: "UpdateEventStatusResponse";
        id: number;
        status: string;
        request: string;
        message?: string | null;
      };
    }>;
  };
};

export type UpdatePortStatusMutationVariables = Exact<{
  eventId: Scalars["Float"];
  status: PortActionEnum;
  type?: InputMaybe<PortTypeEnum>;
}>;

export type UpdatePortStatusMutation = {
  __typename?: "Mutation";
  updatePortStatus: { __typename?: "MutationResponse"; success: boolean };
};

export type UpdatePortStatusByPortNumberMutationVariables = Exact<{
  portNumber: Scalars["Float"];
  status: PortActionEnum;
  type?: InputMaybe<PortTypeEnum>;
}>;

export type UpdatePortStatusByPortNumberMutation = {
  __typename?: "Mutation";
  updatePortStatusByPortNumber: { __typename?: "MutationResponse"; success: boolean };
};

export type GetAppInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppInfoQuery = {
  __typename?: "Query";
  appInfo: { __typename?: "AppInfo"; envName: string };
};

export type GetAttendeesQueryVariables = Exact<{
  eventId: Scalars["Float"];
}>;

export type GetAttendeesQuery = {
  __typename?: "Query";
  auctionAttendees: Array<{
    __typename?: "AuctionAttendee";
    id: number;
    name: string;
    creditLimit?: number | null;
    eventId: number;
    clientAttendeeId: string;
    type: AttendeeTypeEnum;
    warranty?: string | null;
    sellerId?: string | null;
  }>;
};

export type GetAuctionEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuctionEventsQuery = {
  __typename?: "Query";
  auctionEvents: Array<{
    __typename?: "AuctionEvent";
    auctionCompany: string;
    id: number;
    clientEventId: string;
    clientAuctionId?: string | null;
    facilityId: number;
    facilityName: string;
    description: string;
    isPaused: boolean;
    avPort?: number | null;
    bidPort: number;
    updatePort: number;
    masterPort: number;
    startTime: number;
    endTime: number;
    status: EventStatus;
    lotsClerked?: number | null;
    inventoryTable: string;
    inventoryCounts?: Array<{
      __typename?: "InventoryCount";
      count: number;
      eventId: number;
      status: string;
    }> | null;
  }>;
};

export type GetAvPublishersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvPublishersQuery = {
  __typename?: "Query";
  avPublishers: Array<{
    __typename?: "AvPublisher";
    id: number;
    streamId: number;
    description: string;
    status: AvStreamStatus;
    statusInfo?: string | null;
    info?: string | null;
    action?: string | null;
    machineIp?: string | null;
    startTime: number;
    lastModified: number;
    infoRaw?: {
      __typename?: "AvPublisherInfo";
      mic?: {
        __typename?: "AvPublisherMic";
        name?: string | null;
        muted?: boolean | null;
        codec?: string | null;
        rate?: number | null;
        quality?: number | null;
        framesperpacket?: number | null;
      } | null;
      camera?: {
        __typename?: "AvPublisherCamera";
        name?: string | null;
        muted?: boolean | null;
        codec?: string | null;
        profile?: string | null;
        level?: string | null;
        width?: number | null;
        height?: number | null;
        fps?: number | null;
        keyframeinterval?: number | null;
        quality?: string | null;
        bandwidth?: number | null;
      } | null;
      env?: {
        __typename?: "AvPublisherEnvironment";
        playerversion?: string | null;
        os?: string | null;
        debug?: boolean | null;
      } | null;
    } | null;
    avStream: {
      __typename?: "AvStream";
      id: number;
      connectionId: number;
      name: string;
      streamType: StreamTypeEnum;
      audioPort?: number | null;
      encoderId: number;
      avConnection: {
        __typename?: "AvConnection";
        id: number;
        name: string;
        description: string;
        edgeUrl?: string | null;
      };
      avEncoderSettings: {
        __typename?: "AvEncoderSettings";
        id: number;
        description: string;
        audioBitrate: number;
        videoFps: number;
      };
    };
  }>;
};

export type GetAvStreamNameByAudioPortQueryVariables = Exact<{
  audioPort: Scalars["Float"];
}>;

export type GetAvStreamNameByAudioPortQuery = {
  __typename?: "Query";
  avStreamNamebyAudioPort: string;
};

export type GetAvStreamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvStreamsQuery = {
  __typename?: "Query";
  avStreams: Array<{
    __typename?: "AvStream";
    id: number;
    name: string;
    streamType: StreamTypeEnum;
    connectionId: number;
    audioPort?: number | null;
    encoderId: number;
    avConnection: {
      __typename?: "AvConnection";
      id: number;
      name: string;
      description: string;
      edgeUrl?: string | null;
    };
    avConnectionPrimary?: {
      __typename?: "AvConnection";
      id: number;
      name: string;
      description: string;
      edgeUrl?: string | null;
    } | null;
    avConnectionAlternate?: {
      __typename?: "AvConnection";
      id: number;
      name: string;
      description: string;
      edgeUrl?: string | null;
    } | null;
    avConnectionBackup?: {
      __typename?: "AvConnection";
      id: number;
      name: string;
      description: string;
      edgeUrl?: string | null;
    } | null;
    avEncoderSettings: {
      __typename?: "AvEncoderSettings";
      id: number;
      description: string;
      audioBitrate: number;
      videoFps: number;
    };
  }>;
};

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompaniesQuery = {
  __typename?: "Query";
  auctionCompanies: Array<{
    __typename?: "AuctionCompany";
    id: number;
    auctionCompany: string;
    fullName: string;
    flavorGroupName?: string | null;
    flavorGroup?: {
      __typename?: "FlavorGroup";
      flavorGroup: string;
      flavorGroupCollections?: Array<{
        __typename?: "FlavorGroupCollection";
        flavorName: string;
        role: string;
        deviceType: string;
        flavor: { __typename?: "Flavor"; flavor: string; allowPreview?: boolean | null };
      }> | null;
    } | null;
  }>;
};

export type GetCumulativeStatsQueryVariables = Exact<{
  startDate: Scalars["DateTime"];
  endDate: Scalars["DateTime"];
  limit?: InputMaybe<Scalars["Float"]>;
  ahco?: InputMaybe<Scalars["String"]>;
}>;

export type GetCumulativeStatsQuery = {
  __typename?: "Query";
  cumulativeStats: {
    __typename?: "CumulativeStatsDto";
    cumulativeStats?: Array<{
      __typename?: "CumulativeStatDto";
      id: number;
      auctionName: string;
      date: string;
      startTime: string;
      endTime: string;
      runningTime?: number | null;
      totalLoginDuration?: number | null;
      lotsClerked?: number | null;
      totalCars?: number | null;
      totalSold?: number | null;
      soldToNet?: number | null;
      difItemsNetAccepted?: number | null;
      secondHighest?: number | null;
      acceptedBids?: number | null;
      biddersAttended?: number | null;
      netWithoutBid?: number | null;
      acceptedNetBids?: number | null;
      netPurchasedItems?: number | null;
      totalNetSales?: number | null;
      totalSales?: number | null;
      ringmanName: string;
      averagePerItem?: number | null;
      percentageOlrRunnerup?: number | null;
      percentageOlrBid?: number | null;
      percentageOlrSold?: number | null;
      percentageSalesOlr?: number | null;
      averageItemOlr?: number | null;
    }> | null;
    totals: {
      __typename?: "CumulativeStatsTotalsDto";
      totalRunningTime: number;
      totalLoginTime: number;
      totalItems: number;
      totalLotsClerked: number;
      totalSold: number;
      totalSoldOlr: number;
      totalSoldOlrBid: number;
      totalSoldRunnerup: number;
      totalAcceptedBidsOlr: number;
      totalAttendeesOlr: number;
      totalAttendeesOlrWithoutBid: number;
      totalAttendeesOlrBid: number;
      totalAttendeesOlrWon: number;
      totalSales: number;
      totalSalesOlr: number;
      totalAveragePerItem: number;
      totalAveragePerItemOlr: number;
      totalPercentageOlrRunnerup: number;
      totalPercentageOlrBid: number;
      totalPercentageOlrSold: number;
      totalPercentageSalesOlr: number;
    };
  };
};

export type GetCustomerSalesLinksByAhcoQueryVariables = Exact<{
  ahco: Scalars["String"];
}>;

export type GetCustomerSalesLinksByAhcoQuery = {
  __typename?: "Query";
  customerSalesLinksByAhco: {
    __typename?: "CustomerSalesLinks";
    customerLogo?: string | null;
    customerSalesLinks?: Array<{
      __typename?: "CustomerSalesLink";
      facilityName: string;
      laneFullName?: string | null;
      description?: string | null;
      status?: EventStatus | null;
      startTime?: number | null;
      endTime?: number | null;
      eventId?: number | null;
      clientEventId?: string | null;
      ahco: string;
    }> | null;
  };
};

export type GetFacilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFacilitiesQuery = {
  __typename?: "Query";
  facilities: Array<{
    __typename?: "Facility";
    auctionCompany: string;
    facilityId: number;
    facilityName: string;
    flavorGroup?: string | null;
    featureCollectionOverride?: string | null;
    marketplace?: string | null;
    bidPort: number;
    updatePort: number;
    masterPort: number;
    avPort?: number | null;
    auctionEvent?: {
      __typename?: "AuctionEvent";
      auctionCompany: string;
      id: number;
      clientEventId: string;
      facilityId: number;
      facilityName: string;
      description: string;
      isPaused: boolean;
      avPort?: number | null;
      bidPort: number;
      updatePort: number;
      masterPort: number;
      startTime: number;
      endTime: number;
      status: EventStatus;
      inventoryTable: string;
    } | null;
  }>;
};

export type GetHighestPlayerVersionQueryVariables = Exact<{ [key: string]: never }>;

export type GetHighestPlayerVersionQuery = {
  __typename?: "Query";
  highestPlayerVersion: {
    __typename?: "HighestPlayerVersion";
    highestPlayerVersion?: string | null;
  };
};

export type GetLoggedInAttendeeCountsQueryVariables = Exact<{
  type?: InputMaybe<AttendeeTypeEnum>;
  eventId?: InputMaybe<Scalars["Float"]>;
  eventIds?: InputMaybe<Array<Scalars["Float"]> | Scalars["Float"]>;
}>;

export type GetLoggedInAttendeeCountsQuery = {
  __typename?: "Query";
  loggedInAttendeeCounts: Array<{
    __typename?: "AttendeeCount";
    type: AttendeeTypeEnum;
    eventId?: number | null;
    count: number;
  }>;
};

export type GetPortStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetPortStatusQuery = {
  __typename?: "Query";
  getPortStatus: {
    __typename?: "PortStatus";
    updatedOn?: number | null;
    ports: Array<{
      __typename?: "Port";
      portNumber: number;
      status: PortStatusEnum;
      type: PortTypeEnum;
    }>;
  };
};

export type GetPreviewFlavorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPreviewFlavorsQuery = {
  __typename?: "Query";
  flavors: Array<{ __typename?: "Flavor"; flavor: string; allowPreview?: boolean | null }>;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser: { __typename?: "User"; username: string; permissions: Array<Permission> };
};

export type OnPortStatusUpdateSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnPortStatusUpdateSubscription = {
  __typename?: "Subscription";
  portStatusesUpdated: {
    __typename?: "PortStatus";
    updatedOn?: number | null;
    ports: Array<{
      __typename?: "Port";
      status: PortStatusEnum;
      portNumber: number;
      type: PortTypeEnum;
    }>;
  };
};

export const AttendDocument = `
    mutation Attend($ahco: String!, $eventId: Float!, $type: AttendeeTypeEnum!, $flavorOverride: String, $username: String, $devOverride: String) {
  attend(
    ahco: $ahco
    eventId: $eventId
    type: $type
    flavorOverride: $flavorOverride
    username: $username
    devOverride: $devOverride
  )
}
    `;
export const ChangeAvConnectionDocument = `
    mutation ChangeAvConnection($facilityName: String!, $type: AvConnectionTypeEnum!) {
  changeAvConnection(facilityName: $facilityName, type: $type) {
    responses {
      response {
        id
        status
        request
        message
      }
    }
  }
}
    `;
export const CustomerAttendDocument = `
    mutation CustomerAttend($ahco: String!, $eventId: Float!, $type: AttendeeTypeEnum!, $username: String, $password: String) {
  customerAttend(
    ahco: $ahco
    eventId: $eventId
    type: $type
    username: $username
    password: $password
  )
}
    `;
export const GetAuctionHealthStatusDocument = `
    query GetAuctionHealthStatus {
  auctionHealthStatus {
    items {
      ahco
      clientEventId
      eventStatus
      facilityName
      opsStatus
      warnings
    }
  }
}
    `;
export const LoginDocument = `
    mutation Login($userInput: UserInput!) {
  getSessionToken(userInput: $userInput) {
    token
  }
}
    `;
export const MultiAttendDocument = `
    mutation MultiAttend($ahco: String, $flavorOverride: String, $lang: String, $devOverride: String, $clientEventId: [String!]!, $type: AttendeeTypeEnum!, $clientAuctionIds: [String!]!) {
  multiAttend(
    ahco: $ahco
    flavorOverride: $flavorOverride
    lang: $lang
    devOverride: $devOverride
    clientEventId: $clientEventId
    type: $type
    clientAuctionIds: $clientAuctionIds
  )
}
    `;
export const SetAvPublisherActionDocument = `
    mutation SetAvPublisherAction($action: StreamActionEnum!, $avPort: Float!) {
  setAvPublisherAction(action: $action, avPort: $avPort) {
    success
  }
}
    `;
export const SetAvPublisherGainDocument = `
    mutation SetAvPublisherGain($avPort: Float!, $gain: Float!) {
  setAvPublisherGain(avPort: $avPort, gain: $gain) {
    success
  }
}
    `;
export const UpdateEventDatesDocument = `
    mutation UpdateEventDates($eventId: Float!, $startDateTime: DateTime, $endDateTime: DateTime) {
  updateEventDates(
    eventId: $eventId
    startDateTime: $startDateTime
    endDateTime: $endDateTime
  ) {
    responses {
      response {
        id
        status
        request
      }
    }
  }
}
    `;
export const UpdateEventStatusDocument = `
    mutation UpdateEventStatus($eventIds: [Float!]!, $action: EventStatusActions!) {
  updateEventStatus(eventIds: $eventIds, action: $action) {
    responses {
      response {
        id
        status
        request
        message
      }
    }
  }
}
    `;
export const UpdatePortStatusDocument = `
    mutation UpdatePortStatus($eventId: Float!, $status: PortActionEnum!, $type: PortTypeEnum) {
  updatePortStatus(eventId: $eventId, status: $status, type: $type) {
    success
  }
}
    `;
export const UpdatePortStatusByPortNumberDocument = `
    mutation UpdatePortStatusByPortNumber($portNumber: Float!, $status: PortActionEnum!, $type: PortTypeEnum) {
  updatePortStatusByPortNumber(
    portNumber: $portNumber
    status: $status
    type: $type
  ) {
    success
  }
}
    `;
export const GetAppInfoDocument = `
    query GetAppInfo {
  appInfo {
    envName
  }
}
    `;
export const GetAttendeesDocument = `
    query GetAttendees($eventId: Float!) {
  auctionAttendees(eventId: $eventId) {
    id
    name
    creditLimit
    eventId
    clientAttendeeId
    type
    warranty
    sellerId
  }
}
    `;
export const GetAuctionEventsDocument = `
    query GetAuctionEvents {
  auctionEvents {
    auctionCompany
    id
    clientEventId
    clientAuctionId
    facilityId
    facilityName
    description
    isPaused
    avPort
    bidPort
    updatePort
    masterPort
    startTime
    endTime
    status
    lotsClerked
    inventoryTable
    inventoryCounts {
      count
      eventId
      status
    }
  }
}
    `;
export const GetAvPublishersDocument = `
    query GetAvPublishers {
  avPublishers {
    id
    streamId
    description
    status
    statusInfo
    info
    infoRaw {
      mic {
        name
        muted
        codec
        rate
        quality
        framesperpacket
      }
      camera {
        name
        muted
        codec
        profile
        level
        width
        height
        fps
        keyframeinterval
        quality
        bandwidth
      }
      env {
        playerversion
        os
        debug
      }
    }
    action
    machineIp
    startTime
    lastModified
    avStream {
      id
      connectionId
      name
      streamType
      audioPort
      encoderId
      avConnection {
        id
        name
        description
        edgeUrl
      }
      avEncoderSettings {
        id
        description
        audioBitrate
        videoFps
      }
    }
  }
}
    `;
export const GetAvStreamNameByAudioPortDocument = `
    query GetAvStreamNameByAudioPort($audioPort: Float!) {
  avStreamNamebyAudioPort(audioPort: $audioPort)
}
    `;
export const GetAvStreamsDocument = `
    query GetAvStreams {
  avStreams {
    id
    name
    streamType
    connectionId
    audioPort
    encoderId
    avConnection {
      id
      name
      description
      edgeUrl
    }
    avConnectionPrimary {
      id
      name
      description
      edgeUrl
    }
    avConnectionAlternate {
      id
      name
      description
      edgeUrl
    }
    avConnectionBackup {
      id
      name
      description
      edgeUrl
    }
    avEncoderSettings {
      id
      description
      audioBitrate
      videoFps
    }
  }
}
    `;
export const GetCompaniesDocument = `
    query GetCompanies {
  auctionCompanies {
    id
    auctionCompany
    fullName
    flavorGroupName
    flavorGroup {
      flavorGroup
      flavorGroupCollections {
        flavor {
          flavor
          allowPreview
        }
        flavorName
        role
        deviceType
      }
    }
  }
}
    `;
export const GetCumulativeStatsDocument = `
    query GetCumulativeStats($startDate: DateTime!, $endDate: DateTime!, $limit: Float, $ahco: String) {
  cumulativeStats(
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    ahco: $ahco
  ) {
    cumulativeStats {
      id
      auctionName
      date
      startTime
      endTime
      runningTime
      totalLoginDuration
      lotsClerked
      totalCars
      totalSold
      soldToNet
      difItemsNetAccepted
      secondHighest
      acceptedBids
      biddersAttended
      netWithoutBid
      acceptedNetBids
      netPurchasedItems
      totalNetSales
      totalSales
      ringmanName
      averagePerItem
      percentageOlrRunnerup
      percentageOlrBid
      percentageOlrSold
      percentageSalesOlr
      averageItemOlr
    }
    totals {
      totalRunningTime
      totalLoginTime
      totalItems
      totalLotsClerked
      totalSold
      totalSoldOlr
      totalSoldOlrBid
      totalSoldRunnerup
      totalAcceptedBidsOlr
      totalAttendeesOlr
      totalAttendeesOlrWithoutBid
      totalAttendeesOlrBid
      totalAttendeesOlrWon
      totalSales
      totalSalesOlr
      totalAveragePerItem
      totalAveragePerItemOlr
      totalPercentageOlrRunnerup
      totalPercentageOlrBid
      totalPercentageOlrSold
      totalPercentageSalesOlr
    }
  }
}
    `;
export const GetCustomerSalesLinksByAhcoDocument = `
    query GetCustomerSalesLinksByAhco($ahco: String!) {
  customerSalesLinksByAhco(ahco: $ahco) {
    customerLogo
    customerSalesLinks {
      facilityName
      laneFullName
      description
      status
      startTime
      endTime
      eventId
      clientEventId
      ahco
    }
  }
}
    `;
export const GetFacilitiesDocument = `
    query GetFacilities {
  facilities {
    auctionCompany
    facilityId
    facilityName
    flavorGroup
    featureCollectionOverride
    marketplace
    bidPort
    updatePort
    masterPort
    avPort
    auctionEvent {
      auctionCompany
      id
      clientEventId
      facilityId
      facilityName
      description
      isPaused
      avPort
      bidPort
      updatePort
      masterPort
      startTime
      endTime
      status
      inventoryTable
    }
  }
}
    `;
export const GetHighestPlayerVersionDocument = `
    query GetHighestPlayerVersion {
  highestPlayerVersion {
    highestPlayerVersion
  }
}
    `;
export const GetLoggedInAttendeeCountsDocument = `
    query GetLoggedInAttendeeCounts($type: AttendeeTypeEnum, $eventId: Float, $eventIds: [Float!]) {
  loggedInAttendeeCounts(type: $type, eventId: $eventId, eventIds: $eventIds) {
    type
    eventId
    count
  }
}
    `;
export const GetPortStatusDocument = `
    query GetPortStatus {
  getPortStatus {
    updatedOn
    ports {
      portNumber
      status
      type
    }
  }
}
    `;
export const GetPreviewFlavorsDocument = `
    query GetPreviewFlavors {
  flavors(allowPreview: true) {
    flavor
    allowPreview
  }
}
    `;
export const GetUserDocument = `
    query GetUser {
  getUser {
    username
    permissions
  }
}
    `;
export const OnPortStatusUpdateDocument = `
    subscription onPortStatusUpdate {
  portStatusesUpdated {
    updatedOn
    ports {
      status
      portNumber
      type
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    Attend: build.mutation<AttendMutation, AttendMutationVariables>({
      query: (variables) => ({ document: AttendDocument, variables }),
    }),
    ChangeAvConnection: build.mutation<
      ChangeAvConnectionMutation,
      ChangeAvConnectionMutationVariables
    >({
      query: (variables) => ({ document: ChangeAvConnectionDocument, variables }),
    }),
    CustomerAttend: build.mutation<CustomerAttendMutation, CustomerAttendMutationVariables>({
      query: (variables) => ({ document: CustomerAttendDocument, variables }),
    }),
    GetAuctionHealthStatus: build.query<
      GetAuctionHealthStatusQuery,
      GetAuctionHealthStatusQueryVariables | void
    >({
      query: (variables) => ({ document: GetAuctionHealthStatusDocument, variables }),
    }),
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables) => ({ document: LoginDocument, variables }),
    }),
    MultiAttend: build.mutation<MultiAttendMutation, MultiAttendMutationVariables>({
      query: (variables) => ({ document: MultiAttendDocument, variables }),
    }),
    SetAvPublisherAction: build.mutation<
      SetAvPublisherActionMutation,
      SetAvPublisherActionMutationVariables
    >({
      query: (variables) => ({ document: SetAvPublisherActionDocument, variables }),
    }),
    SetAvPublisherGain: build.mutation<
      SetAvPublisherGainMutation,
      SetAvPublisherGainMutationVariables
    >({
      query: (variables) => ({ document: SetAvPublisherGainDocument, variables }),
    }),
    UpdateEventDates: build.mutation<UpdateEventDatesMutation, UpdateEventDatesMutationVariables>({
      query: (variables) => ({ document: UpdateEventDatesDocument, variables }),
    }),
    UpdateEventStatus: build.mutation<
      UpdateEventStatusMutation,
      UpdateEventStatusMutationVariables
    >({
      query: (variables) => ({ document: UpdateEventStatusDocument, variables }),
    }),
    UpdatePortStatus: build.mutation<UpdatePortStatusMutation, UpdatePortStatusMutationVariables>({
      query: (variables) => ({ document: UpdatePortStatusDocument, variables }),
    }),
    UpdatePortStatusByPortNumber: build.mutation<
      UpdatePortStatusByPortNumberMutation,
      UpdatePortStatusByPortNumberMutationVariables
    >({
      query: (variables) => ({ document: UpdatePortStatusByPortNumberDocument, variables }),
    }),
    GetAppInfo: build.query<GetAppInfoQuery, GetAppInfoQueryVariables | void>({
      query: (variables) => ({ document: GetAppInfoDocument, variables }),
    }),
    GetAttendees: build.query<GetAttendeesQuery, GetAttendeesQueryVariables>({
      query: (variables) => ({ document: GetAttendeesDocument, variables }),
    }),
    GetAuctionEvents: build.query<GetAuctionEventsQuery, GetAuctionEventsQueryVariables | void>({
      query: (variables) => ({ document: GetAuctionEventsDocument, variables }),
    }),
    GetAvPublishers: build.query<GetAvPublishersQuery, GetAvPublishersQueryVariables | void>({
      query: (variables) => ({ document: GetAvPublishersDocument, variables }),
    }),
    GetAvStreamNameByAudioPort: build.query<
      GetAvStreamNameByAudioPortQuery,
      GetAvStreamNameByAudioPortQueryVariables
    >({
      query: (variables) => ({ document: GetAvStreamNameByAudioPortDocument, variables }),
    }),
    GetAvStreams: build.query<GetAvStreamsQuery, GetAvStreamsQueryVariables | void>({
      query: (variables) => ({ document: GetAvStreamsDocument, variables }),
    }),
    GetCompanies: build.query<GetCompaniesQuery, GetCompaniesQueryVariables | void>({
      query: (variables) => ({ document: GetCompaniesDocument, variables }),
    }),
    GetCumulativeStats: build.query<GetCumulativeStatsQuery, GetCumulativeStatsQueryVariables>({
      query: (variables) => ({ document: GetCumulativeStatsDocument, variables }),
    }),
    GetCustomerSalesLinksByAhco: build.query<
      GetCustomerSalesLinksByAhcoQuery,
      GetCustomerSalesLinksByAhcoQueryVariables
    >({
      query: (variables) => ({ document: GetCustomerSalesLinksByAhcoDocument, variables }),
    }),
    GetFacilities: build.query<GetFacilitiesQuery, GetFacilitiesQueryVariables | void>({
      query: (variables) => ({ document: GetFacilitiesDocument, variables }),
    }),
    GetHighestPlayerVersion: build.query<
      GetHighestPlayerVersionQuery,
      GetHighestPlayerVersionQueryVariables | void
    >({
      query: (variables) => ({ document: GetHighestPlayerVersionDocument, variables }),
    }),
    GetLoggedInAttendeeCounts: build.query<
      GetLoggedInAttendeeCountsQuery,
      GetLoggedInAttendeeCountsQueryVariables | void
    >({
      query: (variables) => ({ document: GetLoggedInAttendeeCountsDocument, variables }),
    }),
    GetPortStatus: build.query<GetPortStatusQuery, GetPortStatusQueryVariables | void>({
      query: (variables) => ({ document: GetPortStatusDocument, variables }),
    }),
    GetPreviewFlavors: build.query<GetPreviewFlavorsQuery, GetPreviewFlavorsQueryVariables | void>({
      query: (variables) => ({ document: GetPreviewFlavorsDocument, variables }),
    }),
    GetUser: build.query<GetUserQuery, GetUserQueryVariables | void>({
      query: (variables) => ({ document: GetUserDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useAttendMutation,
  useChangeAvConnectionMutation,
  useCustomerAttendMutation,
  useGetAuctionHealthStatusQuery,
  useLazyGetAuctionHealthStatusQuery,
  useLoginMutation,
  useMultiAttendMutation,
  useSetAvPublisherActionMutation,
  useSetAvPublisherGainMutation,
  useUpdateEventDatesMutation,
  useUpdateEventStatusMutation,
  useUpdatePortStatusMutation,
  useUpdatePortStatusByPortNumberMutation,
  useGetAppInfoQuery,
  useLazyGetAppInfoQuery,
  useGetAttendeesQuery,
  useLazyGetAttendeesQuery,
  useGetAuctionEventsQuery,
  useLazyGetAuctionEventsQuery,
  useGetAvPublishersQuery,
  useLazyGetAvPublishersQuery,
  useGetAvStreamNameByAudioPortQuery,
  useLazyGetAvStreamNameByAudioPortQuery,
  useGetAvStreamsQuery,
  useLazyGetAvStreamsQuery,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCumulativeStatsQuery,
  useLazyGetCumulativeStatsQuery,
  useGetCustomerSalesLinksByAhcoQuery,
  useLazyGetCustomerSalesLinksByAhcoQuery,
  useGetFacilitiesQuery,
  useLazyGetFacilitiesQuery,
  useGetHighestPlayerVersionQuery,
  useLazyGetHighestPlayerVersionQuery,
  useGetLoggedInAttendeeCountsQuery,
  useLazyGetLoggedInAttendeeCountsQuery,
  useGetPortStatusQuery,
  useLazyGetPortStatusQuery,
  useGetPreviewFlavorsQuery,
  useLazyGetPreviewFlavorsQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
} = injectedRtkApi;
